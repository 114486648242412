import React, { useState } from 'react';

const EnlargableImage = ({ src }) => {
  const [enlarged, setEnlarged] = useState(false);

  const toggleEnlarged = () => {
    setEnlarged(!enlarged);
  };

  return (
    
    <img
      src={src}
      alt="Enlargable Image"
      className={enlarged ? 'enlarged' : ''}
      onClick={toggleEnlarged}
      style={{display:'block'}}
    />
  );
};

export default EnlargableImage;
