import React from 'react';
import { useState,useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import CakesSideBar from './CakesSideBar';
import SaleReportTable from './SaleReportTable';
import Sales from './RealTimeSalesData';
import Stock from './RealTimeStockData';

const Places = [
    {name:"Ilford lane", id:17},
    {name:"Edmonton", id:14},
    {name:"Leyton", id:15},
    {name:"Norwood", id:2},
    {name:"South Woodford", id:8},
    {name:"Walthamstow", id:11},
    
  ];
const SaleReport = () => {
    const [inventoryData, setInventoryData] = useState([{}]);
    const [inventoryData2, setInventoryData2] = useState([{}]);
    const [inventoryOrders, setInventoryOrders] = useState([{}]);
    const [inventoryProducts, setInventoryProducts] = useState([{}]);
    const [sales, setSales] = useState({});
    const [stock, setStock] = useState({});
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [startdate, setStartdate] = useState(new Date ());
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  const [duplicatedate, setDuplicatedate] = useState(new Date ());
  const getWeekStartDate = (date) => {
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };
  const getFormattedDate = (date) => {
    const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    return formattedDate;
  };

  // Function to get the end date of the week
  const getWeekEndDate = (date) => {
    const weekStartDate = getWeekStartDate(date);
    return new Date(weekStartDate.setDate(weekStartDate.getDate() + 6));
  };
  const getPreviousDate = (date) => {
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate;
  };

  const weekStartDate = getWeekStartDate(duplicatedate);
  const weekEndDate = getWeekEndDate(duplicatedate);
  const previousSunday = getPreviousDate(weekStartDate);
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  const handleDateChange = (event) => {
    setStartdate(new Date(event.target.value));
    setDuplicatedate(new Date(event.target.value))
  };
  const fetchAllyesterdayData = async (startDate, setResponse, setErr) => {
    setError('')
    const dateFormat =startDate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://localhost:8800/api/cake/getSingleDayinventory",
        {
          dateFormat: dateFormat,
          store: location,
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setResponse([{}]);
        setErr(`There is no Inventory data for ${location} on ${dateFormat}`);
        return
      }
      setResponse(response.data.length ==0?[{}]:response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getProductsData = async(OrdersData) =>{
      try {
        const responses = await Promise.all(
          OrdersData.map(item =>
            axios.get(`http://localhost:5005/api/getProductsData/${item.id}`)
          )
        );
        const allData = responses.reduce((acc, response) => {
          return acc.concat(response.data);
        }, []);
        setInventoryProducts(allData);
      } 
      catch (error) {
        setError(error);
      } 
    };

  console.log('InventoryProducts', inventoryProducts);
  const fetchInventoryOrdersData = async () => {
    setError('')
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://localhost:5005/api/getStoresData",
        {
          id:locationId,
          startDate:weekStartDate.toISOString(),
          endDate:weekEndDate.toISOString(),
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setInventoryOrders([{}]);
        setError(`There is no Inventory orders for ${location} on selected peroid`);
        return
      }
      setInventoryOrders(response.data.length ==0?[{}]:response.data);
      getProductsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const changelocation = (e) => {
    setLocation(e.target.value);
    const selectedIndex = e.target.selectedIndex;
    const selectedId = e.target.options[selectedIndex].getAttribute('data-id');
    setLocationId(selectedId);
  };
  const getInventoryData = ()=>{
    if(location == '')
        {
          setError('Please select Location!')
          return
        }
    fetchAllyesterdayData(getPreviousDate(weekStartDate), setInventoryData, setError);
    fetchAllyesterdayData(weekEndDate, setInventoryData2, setError1);
    
  }
  const getStockandSalesData =()=>{
    if(location == '')
        {
          setError('Please select Location!')
          return
        }
    setSales(Sales);
    setStock(Stock);
    fetchInventoryOrdersData();
  }
  
      return (
        <div >
          <CakesSideBar />
          
          <div  style={{ marginLeft: 150,marginTop:30 }}>
    <Link to="/cakes&bakes" >
  <div style={{display: 'flex'}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
          <p style={{textAlign:'left', marginTop:20,fontSize:36, color: '#244999'}} >Cakes & Bakes Sales Report</p>

             <p style={{color:'red', alignItems:'center'}}>{error}</p>
             <p style={{color:'red', alignItems:'center'}}>{error1}</p>
       <div className="row">
         <div className="col-md-3" style={{marginTop:10}}>
           <select
             className="form-control"
             value={location}
             onChange={changelocation}
             style={{color: '#121314',fontSize:20, height:40}}
           >
             <option value="">Location</option>
             {Places.map((place, index) => (
               <option key={index} value={place.name} data-id={place.id}>
               {place.name}
             </option>
             ))}
           </select>
         </div>
         <div className="col-md-3" style={{marginTop:10}}>
       <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5}}>
            <div style={{borderRight:'1px solid #DCDCDC',height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label style={{textAlign:'center',color:'#6E6E6E'}}>Select Date </label>
            </div>
            <input
              type="date"
              value={startdate.toISOString().split('T')[0]}
              onChange={handleDateChange}
              style={{ marginLeft: 5,border: 'none' , height:30,color:'#121314', fontSize:20,marginBottom:5,marginTop:5,}}
            />
            </div>
          </div>
        <div className="col-md-3" style={{marginTop:10}}>
           <button onClick={getInventoryData} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Get Inventory Data</button>
         </div>
         <div className="col-md-3" style={{marginTop:10}}>
           <button onClick={getStockandSalesData} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Get Stock and Sales Data</button>
         </div>
         
         
         
       </div>
       <div className='row' style={{marginTop:20}}>
        <div className='col-md-3'>
            <h6>Selected Date </h6>
       <p >
        {getFormattedDate(startdate)}</p>
       </div>
       <div className='col-md-3'>
       <h6>Start of Week</h6>
      <p>{getFormattedDate(weekStartDate)}</p>
      </div>
       <div className='col-md-3'>
       <h6>End of Week</h6>
      <p>{getFormattedDate(weekEndDate)}</p>
      </div>
      </div>
      <div   style={{marginTop:30, display:'block'}}>
      
      <table class="table " >
    <thead>
    <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Product</td>
          <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Inventory on {getFormattedDate(previousSunday)}(a)</td>
          <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Fresh Stock Sent on {getFormattedDate(weekStartDate)}(b)</td>
          <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Sales({getFormattedDate(weekStartDate)} - {getFormattedDate(weekEndDate)})(s)</td>
          <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Diff((a+b) - s)</td>
          <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Inventory on {getFormattedDate(weekEndDate)}</td>
          <td scope="col" style={{fontSize:16,  borderWidth:0, color:'#476192'}}>Wastages({getFormattedDate(weekStartDate)} - {getFormattedDate(weekEndDate)})</td>
          
    </thead>
      <SaleReportTable inventoryData2={inventoryData2} inventoryData={inventoryData} sales={sales} stock={inventoryProducts}/>
      </table>
    </div>
    </div>
        </div>
      );
};

export default SaleReport;
