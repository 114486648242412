const Stock={
    "cake1": "0",
    "cake2": "0",
    "cake3": "0",
    "cake4": "0",
    "cake5": "0",
    "cake6": "0",
    "cake7": "0",
    "cake8": "0",
    "cake9": "0",
    "cake10": "0",
    "cake11": "0",
    "cake12": "0",
    "cake13": "0",
    "cake14": "0",
    "cake15": "2",
    "cake16": "0",
    "cake17": "0",
    "cake18": "0",
    "cake19": "0",
    "cake20": "0",
    "cake21": "2",
    "cake22": "1",
    "cake23": "2",
    "cake24": "0",
    "slice1": "1",
    "slice2": "1",
    "slice3": "1",
    "slice4": "1",
    "slice5": "1",
    "slice6": "1",
    "slice7": "1",
    "slice8": "1",
    "slice9": "1",
    "slice10": "0",
    "slice11": "0",
    "slice12": "0",
    "slice13": "1",
    "cup1": "0",
    "cup2": "1",
    "cup3": "1",
    "cup4": "0",
    "cup5": "0",
    "cup6": "1",
    "cup7": "1",
    "cup8": "1",
    "cup9": "1",
    "cup10": "1",
    "cup11": "0",
    "cup12": "1",
    "cup13": "0",
}

export default Stock;