import React, { useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import dashboard1 from "./assets/dashboard1.png";
import dashboard2 from "./assets/dashboard2.png";
import dashboard3 from "./assets/dashboard3.png";
import walaLogo from "./assets/WalaLogo.png";

const WalaLayout = () => {
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  return (
    <div className="App-header" style={{backgroundColor:'#244999'}}>
      <div className="row" style={{margin:10}}>
      <Link to="/hyderabadwala/summary" style={{ borderRadius:5,height:'200px', width:200, color: 'white', border: '2px solid white',  marginRight:30, backgroundColor:'white', alignItems:'center', justifyContent:'center',marginTop:10  }}>
        <div style={{marginTop:70}}>
        <img src={dashboard1} alt="Description of the image" style={{width:50, height:50}}/>
        <p style={{color:'#5D5D5D', fontSize:14}}>Day Sheets Summary</p>
        </div>
        </Link>
     
          
          <Link to="/hyderabadwala/verify" style={{borderRadius:5, height:'200px', width:200, color: 'white', border: '2px solid white',  marginRight:30, backgroundColor:'white', alignItems:'center', justifyContent:'center',marginTop:10  }}>
        <div style={{marginTop:70}}>
        <img src={dashboard2} alt="Description of the image" style={{width:50, height:50}}/>
        <p style={{color:'#5D5D5D', fontSize:14}}>Day Sheet Verification</p>
        </div>
        </Link>
          <Link to="/hyderabadwala/inventory" style={{borderRadius:5, height:'200px', width:200, color: 'white', border: '2px solid white',  marginRight:30, backgroundColor:'white', alignItems:'center', justifyContent:'center',marginTop:10  }}>
        <div style={{marginTop:70}}>
        <img src={walaLogo} alt="Description of the image" style={{width:50, height:50}}/>
        <p style={{color:'#5D5D5D', fontSize:14}}>Inventory</p>
        </div>
        </Link>
        
          
          </div>
 </div>
  )
};

export default WalaLayout;