import Accordion from "react-bootstrap/Accordion";
import OnlineTable from "./OnlineTable";

function OnlineAccordion({ data }) {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ fontSize: '16px' }}> {`£${data.onlineTotal}`}</Accordion.Header>
        <Accordion.Body>
         <OnlineTable data ={data}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default OnlineAccordion;
