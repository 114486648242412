import { useState,useRef,useEffect } from "react";
import axios from "axios";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { Link } from "react-router-dom";
import {IoIosArrowDropleftCircle  } from 'react-icons/io';
import Sidebar from "./Sidebar";
import './Table.css';
import { useNavigate } from 'react-router-dom';
import excel from "./assets/excel.png";
import CakesSideBar from "./CakesSideBar";
import SaleReportBody from "./SaleReportBody";
import CustomSideBar from "./CustomSideBar";

const Places = [
  "Ilford lane",
  "Leyton",
  "Norwood",
  "Ruislip Manor",
  "South Woodford",
  "Walthamstow",
];
const tableHeaders=['Store','Date','Cash To Management','Store Sale', 'Online Sales',]
function CakesCashSaleReportjs() {
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");
  const [duration, setDuration] = useState("");
  const [startdate, setStartdate] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [store, setStore] = useState("");
  const today = new Date();
  const [selectedstartDate, setSelectedstartDate] = useState(new Date ());
  const [selectedendDate, setSelectedendDate] = useState(new Date ());
  const [visible, setVisible] = useState(false);
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
 
  const handlestartDateChange = (event) => {
    setSelectedstartDate(new Date(event.target.value));
  };
  const handleendDateChange = (event) => {
    setSelectedendDate(new Date(event.target.value));
  };
  const changelocation = (event) => {
    setLocation(event.target.value);
  };
  var yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
  const formattedYesterdayDate = yesterday.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const changeduration = (event) => {
    setDuration(event.target.value);
    if (event.target.value === "weekly") {
      var week = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7);
      setStartdate(week.toISOString());
      setVisible(false);
    }
    if (event.target.value === "monthly") {
      var month = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30);
      setStartdate(month.toISOString());
      setVisible(false);
    }
    if (event.target.value === "quaterly") {
      var quater = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 90);
      setStartdate(quater.toISOString());
      setVisible(false);
    }
    if (event.target.value === "custom") {
     
      setVisible(true);
    }
    if (event.target.value === "yesterday") {
     
      setVisible(false);
    }
  };
  const fetchyesterdayData = async () => {
    setError('');
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/yesterdayDaySheet",
        {
          store: location,
          dateFormat: formattedYesterdayDate,
          shop:'Cakes & Bakes',
        },
        {
          headers:headers
        }
      );
      if(!(response.data)){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      setData(response.data ? [response.data] : []);
      setError('');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAllyesterdayData = async () => {
    setError('');
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/previousdayAllDaySheets",
        {
          dateFormat: formattedYesterdayDate,
          shop:'Cakes & Bakes',
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      setData(response.data ? response.data : []);
      setError('');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAllLocationData = async () => {
    setError('');
    if(duration === "custom"){
      if(selectedendDate<selectedstartDate)
    {
      setError('End date should greater than start date')
      return
    }
    if(selectedendDate.getTime() === selectedstartDate.getTime())
    {
      setError('End date should greater than start date')
      return
    }
    }
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/getAllLocationsDaysheets",
        {
          startdate: duration === "custom"? selectedstartDate.toISOString():startdate,
          enddate: duration === "custom"? selectedendDate.toISOString():today.toISOString(),
          shop:'Cakes & Bakes',
          
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      console.log("Data received successful:", response.data);
      setData(response.data);
      setError('');
    } catch (error) {
      console.error("Error registering user:", error.message);
      setError('Something went wrong')
    }
  };
  const fetchData = async () => {
    setError('');
    if(duration === "custom"){
      if(selectedendDate<selectedstartDate)
    {
      setError('End date should greater than start date')
      return
    }
    if(selectedendDate.getTime() === selectedstartDate.getTime())
    {
      setError('End date should greater than start date')
      return
    }
    }
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/getCustomDurationDaysheets",
        {
          startdate: duration === "custom"? selectedstartDate.toISOString():startdate,
          enddate: duration === "custom"? selectedendDate.toISOString():today.toISOString(),
          store: location,
          shop:'Cakes & Bakes',
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      console.log("Data received successful:", response.data);
      setData(response.data);
      setError('');
    } catch (error) {
      console.error("Error registering user:", error.message);
      setError('Something went wrong')
    }
  };
  const getData = () => {
   
    if(location == '')
    {
      setError('Please select Location!')
      return
    }
    if(duration == '')
    {
      setError('Please select Duration')
      return
    }
    if(location==='All'&& duration === "yesterday"){
      fetchAllyesterdayData();
      return
    }
    if(location==='All' && duration !== "yesterday"){
      fetchAllLocationData();
      return
    }
    if (duration === "yesterday") {
      fetchyesterdayData();
    } else {
      fetchData();
    }
  };

  return (
    <>
     <CustomSideBar />
    <div  style={{ marginLeft: 150,marginTop:30 }}>
    <Link to="/cakes&bakes" >
  <div style={{display: 'flex'}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
        
        <p style={{textAlign:'left', marginTop:20,fontSize:36, color: '#244999'}}>Cakes & Bakes Sale Report</p>
        <p style={{ color: 'red' ,textAlign:'left',}}>{error}</p>
        <div className="row" style={{marginTop:20}}>
        
          <div className="select-wrapper col-md-3" style={{marginTop:10}}>
            <select
              className="form-control"
              value={location}
              onChange={changelocation}
              style={{color: '#121314',fontSize:20,height:40}}
            >
              <option style={{color: '#121314',fontSize:20,}}>Location</option>
              {Places.map((place) => (
                <option value={place} style={{color: '#121314',fontSize:20,}}>{place}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3" style={{marginTop:10}}>
            <select
              className="form-control"
              value={duration}
              onChange={changeduration}
              style={{color: '#121314',fontSize:20,height:40}}
            >
              <option style={{color: '#121314',fontSize:20,}}>Duration</option>
              <option value="yesterday" style={{color: '#121314',fontSize:20,}}>Yesterday</option>
              <option value="weekly" style={{color: '#121314',fontSize:20,}}>Last 7 days</option>
              <option value="monthly" style={{color: '#121314',fontSize:20,}}>Last 30 days</option>
              <option value="quaterly" style={{color: '#121314',fontSize:20,}}>Last 90 days</option>
              <option value="custom" style={{color: '#121314',fontSize:20,}}>Custom Duration</option>
            </select>
          </div>
          <div className="col-md-3" style={{marginTop:10}}>
            {/* <button onClick={sendDataToServer}>Send Date</button> */}
            <button onClick={getData} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Get Data</button>
          </div>
          <div className="col-md-3" style={{marginTop:10}}>
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >

              <button style={{fontSize:20,height:40, color:'#FFFFFF',backgroundColor:'#048E5B',borderWidth:0,borderRadius:5}} > <img src={excel} alt="Logo" style={{width:18, height:20}}/> Export excel </button>

            </DownloadTableExcel>
          </div>

        </div>
        {visible && <div className="row" style={{ marginTop: 50, alignItems:'center', justifyContent:'center' }}>
        <div className="col-md-3" style={{ alignItems:'center', justifyContent:'center',marginLeft:15,marginTop:10}}>
            <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5}}>
            <div style={{borderRight:'1px solid #DCDCDC',height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label style={{textAlign:'center',color:'#6E6E6E'}}>Start Date: </label>
            </div>
            <input
              type="date"
              value={selectedstartDate.toISOString().split('T')[0]}
              onChange={handlestartDateChange}
              style={{ marginLeft: 25,border: 'none' , height:30,marginTop:5,marginBottom:5,color:'#121314', fontSize:20}} />
              </div>
          </div>
          <div className="col-md-3" style={{ alignItems:'center', justifyContent:'center',marginLeft:15, marginTop:10}}>
            <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5}}>
            <div style={{borderRight:'1px solid #DCDCDC',height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label style={{textAlign:'center',color:'#6E6E6E'}}>End Date </label>
            </div>
            <input
              type="date"
              value={selectedendDate.toISOString().split('T')[0]}
              onChange={handleendDateChange}
              style={{ marginLeft: 25,border: 'none' , marginTop:5,marginBottom:5,height:30,color:'#121314', fontSize:20}} />
          </div>
          </div>
        </div>}
        <div className="col-md-6" style={{ marginTop: 50 }}>
          <table class="table " ref={tableRef}>
          <thead>
      <tr>
        {tableHeaders.map((header) => (
          <th scope="row" style={{ borderWidth:0, color:'#476192', fontSize:14}}>{header}</th>
        ))}
      </tr>
    </thead>
            <SaleReportBody contents={data} setError={setError} />
          </table>
         
        </div>
      </div></>
  );
}

export default CakesCashSaleReportjs;
