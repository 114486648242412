import Accordion from "react-bootstrap/Accordion";
import GoodToGoTable from "./GoodToGoTable";

function GoodToGoAccordion({ data }) {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ fontSize: '16px' }}> Good To Go</Accordion.Header>
        <Accordion.Body>
          <GoodToGoTable data={data}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default GoodToGoAccordion;
