import React, { useState } from 'react';
import axios from 'axios';
import AccordionExample from './Accordian';
import PurchaseAccordion from './PurchaseAccordian';
import WastageAccordion from './wastageAccordian';
import GoodToGoAccordion from './GoodToGoAccordian';
import VerificationCheckBox from './Verification';
import OnlineAccordion from './OnlineAccordian';
import CommentsInput from './CommentsInput';
import TableBodyElement from './TableBodyElement';

const CompleteTableBody =({content, setError})=>{
    const [isyestFloat, setIsyestFloat] = useState(content.isyestFloat);
    const [ispettycash, setIspettycash] = useState(content.ispettycash);
    const [iscashsale, setIscashsale] = useState(content.iscashsale);
    const [iscard, setIscard] = useState(content.iscard);
    const [isstoresale, setIsstoresale] = useState(content.isstoresale);
    const [issubtotal, setIssubtotal] = useState(content.issubtotal);
    const [istillcash, setIstillcash] = useState(content.istillcash);
    const [iscashmanag, setIscashmanag] = useState(content.iscashmanag);
    const [istodayfloat, setIstodayfloat] = useState(content.istodayfloat);
    const [istotalSales, setIstotalSales] = useState(content.istotalSales);
    const [isonline, setIsonline] = useState(content.isonline);
    const [iswastage, setIswastage] = useState(content.iswastage);
    const [isgoodtogo, setIsgoodtogo] = useState(content.isgoodtogo);
    const [isChecked, setIsChecked] = useState(content.isSubmitted);
    const [isSubmit, setSubmit] = useState(content.isSubmitted);
    const [comment, setComment] = useState(content.comment);
    const sumTotal = content?.wastages?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.totalprice);
    }, 0);
    const handleCheckboxChange = async() => {
      if(comment === ''){
        setError('Please enter the comments')
        return
      }
      const submittedFlag = ispettycash && iscashsale && isChecked && isyestFloat && iscard&&iscashmanag
      && isstoresale && issubtotal && istodayfloat &&istotalSales&&isonline&&iswastage&&isgoodtogo&&istillcash
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      try {
              const response = await axios.post('http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/verification',{
                  id:content._id,
                  verified:isChecked,
                  comment:comment,
                  isyestFloat: isyestFloat, 
  ispettycash:ispettycash, 
  iscashsale: iscashsale,
  iscard:iscard, 
  isstoresale: isstoresale,
  issubtotal:issubtotal, 
  istodayfloat: istodayfloat,
  istotalSales:istotalSales, 
  isonline: isonline,
  iswastage:iswastage, 
  isgoodtogo: isgoodtogo,
  istillcash:istillcash,
  isSubmitted:submittedFlag,
  iscashmanag:iscashmanag,
              },
              {
                headers:headers
              });
             
              console.log('submittedFlag***', submittedFlag);
              setSubmit(submittedFlag)
              setError('')
              console.log('Data received successful:', response.data);
            } catch (error) {
              console.error('Error registering user:', error.message);
              setError('Something went wrong! Please try again');
            }
     
      };
    return(
        
          <><>
           <td>{content.store}</td>
          <td>{content.dateFormat}</td>
          <td>{`£${content.tillcash}`}</td>
          <td ><TableBodyElement isChecked={isyestFloat} setIsChecked={setIsyestFloat} data={content.yesfloat} isSubmit={isSubmit}/></td>
          <td>{`£${content.pettycash}`}</td>
      <td style={{backgroundColor: content.iscashsale ? '#88EE88' : '#FF6868' }}><TableBodyElement isChecked={iscashsale} setIsChecked={setIscashsale} data={content.cashsale} isSubmit={isSubmit}/></td>
      <td style={{backgroundColor: content.iscard ? '#88EE88' : '#FF6868' }}><TableBodyElement isChecked={iscard} setIsChecked={setIscard} data={content.card} isSubmit={isSubmit}/></td>
      <td style={{backgroundColor: content.isstoresale ? '#88EE88' : '#FF6868' }}><TableBodyElement isChecked={isstoresale} setIsChecked={setIsstoresale} data={content.storesales} isSubmit={isSubmit}/></td>
      <td style={{backgroundColor: content.issubtotal ? '#88EE88' : '#FF6868' }}><TableBodyElement isChecked={issubtotal} setIsChecked={setIssubtotal} data={content.subtotal} isSubmit={isSubmit}/></td>
      <td style={{backgroundColor: content.iscashmanag ? '#88EE88' : '#FF6868' }}><TableBodyElement isChecked={iscashmanag} setIsChecked={setIscashmanag} data={content.cashmanag} isSubmit={isSubmit}/></td>
      <td style={{backgroundColor: content.istodayfloat ? '#88EE88' : '#FF6868' }}><TableBodyElement isSubmit={isSubmit} isChecked={istodayfloat} setIsChecked={setIstodayfloat} data={content.todayfloat} /></td>
      <td>{`£${content.onlineTotal}`}</td>
        <td style={{backgroundColor: content.istotalSales ? '#88EE88' : '#FF6868' }}><TableBodyElement isChecked={istotalSales} setIsChecked={setIstotalSales} data={content.totalSales} isSubmit={isSubmit}/></td>
        <td>{`£${sumTotal?.toFixed(2)}`}</td>
          <td>{`£${content.goodToGoTotal}`}</td></>
          <td>{content.otherinfo}</td>
            <td>{isChecked?'Verified':'Not Verfied'}</td>
            <td>{content.comment}</td></>
       
          
    )
}

export default CompleteTableBody;