import React from 'react';

import CompleteTableBody from './CompleteTableBody';

const TableBody = ({ contents, setError }) => {
  return (
    <tbody>
           
        {contents.map((content) => (
           <tr key={content._id} >
          <CompleteTableBody content={content} setError={setError}/>
          </tr>
        ))}
     
    </tbody>
  );
};

export default TableBody;




