import React from 'react';
import { useState,useRef, useEffect } from "react";
import axios from "axios";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CakesHeader from './CakesHeader';
import CakesBody from './CakesBody';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import CakesSideBar from './CakesSideBar';
import RequirementsTable from './RequrimentsTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Places = [
  "Ilford lane",
  "Edminton",
  "Leyton",
  "Norwood",
  "Ruislip Manor",
  "South Woodford",
  "Walthamstow",
  ];
const RequirementsList = () => {
    const tableRef = useRef(null);
    const [inventoryData, setInventoryData] = useState([{}]);
    const [requireData, setRequireData] = useState({});
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");
  const [startdate, setStartdate] = useState(new Date ());
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  const [cake1, setCake1] = useState(requireData.cake1)
  const [cake2, setCake2] = useState('');
    const [cake3, setCake3] = useState('');
    const [cake4, setCake4] = useState('');
    const [cake5, setCake5] = useState('');
    const [cake6, setCake6] = useState('');
    const [cake7, setCake7] = useState('');
    const [cake8, setCake8] = useState('');
    const [cake9, setCake9] = useState('');
    const [cake10, setCake10] = useState('');
    const [cake11, setCake11] = useState('');
    const [cake12, setCake12] = useState('');
    const [cake13, setCake13] = useState('');
    const [cake14, setCake14] = useState('');
    const [cake15, setCake15] = useState('');
    const [cake16, setCake16] = useState('');
    const [cake17, setCake17] = useState('');
    const [cake18, setCake18] = useState('');
    const [cake19, setCake19] = useState('');
    const [cake20, setCake20] = useState('');
    const [cake21, setCake21] = useState('');
    const [cake22, setCake22] = useState('');
    const [cake23, setCake23] = useState('');
    const [cake24, setCake24] = useState('');
    const [cup1, setCup1] = useState();
    const [cup2, setCup2] = useState();
    const [cup3, setCup3] = useState();
    const [cup4, setCup4] = useState();
    const [cup5, setCup5] = useState();
    const [cup6, setCup6] = useState();
    const [cup7, setCup7] = useState();
    const [cup8, setCup8] = useState();
    const [cup9, setCup9] = useState();
    const [cup10, setCup10] = useState();
    const [cup11, setCup11] = useState();
    const [cup12, setCup12] = useState();
    const [cup13, setCup13] = useState();
    const [slice1, setSlice1] = useState();
    const [slice2, setSlice2] = useState();
    const [slice3, setSlice3] = useState();
    const [slice4, setSlice4] = useState();
    const [slice5, setSlice5] = useState();
    const [slice6, setSlice6] = useState();
    const [slice7, setSlice7] = useState();
    const [slice8, setSlice8] = useState();
    const [slice9, setSlice9] = useState();
    const [slice10, setSlice10] = useState();
    const [slice11, setSlice11] = useState();
    const [slice12, setSlice12] = useState();
    const [slice13, setSlice13] = useState();
  
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  const showToast = () => {
    toast.success('Requirements List Updated Successfully', {
      position: 'top-right',
      autoClose: 2000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const getRequirementList = async (locationValue) => {
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/requirementinventory/getLocationRequrimentinventory",
        {
          location: locationValue,
        },
        {
          headers:headers
        }
      );
      setRequireData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const changelocation = (event) => {
    setLocation(event.target.value);
    getRequirementList(event.target.value);
  };
  const updateRequirementList = async () => {
    
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.put(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/requirementinventory/updateRequrimentinventory",
        {
          id:requireData._id,
          slice1:slice1,
          slice2:slice2,
          slice3:slice3,
          slice4:slice4,
          slice5:slice5,
          slice6:slice6,
          slice7:slice7,
          slice8:slice8,
          slice9:slice9,
          slice10:slice10,
          slice11:slice11,
          slice12:slice12,
          slice13:slice13,
          cup1:cup1,
    cup2:cup2,
    cup3:cup3,
    cup4:cup4,
    cup5:cup5,
    cup6:cup6,
    cup7:cup7,
    cup8:cup8,
    cup9:cup9,
    cup10:cup10,
    cup11:cup11,
    cup12:cup12,
    cup13:cup13,
    cake1:cake1,
    cake2:cake2,
    cake3:cake3,
    cake4:cake4,
    cake5:cake5,
    cake6:cake6,
    cake7:cake7,
    cake8:cake8,
    cake9:cake9,
    cake10:cake10,
    cake11:cake11,
    cake12:cake12,
    cake13:cake13,
    cake14:cake14,
    cake15:cake15,
    cake16:cake16,
    cake17:cake17,
    cake18:cake18,
    cake19:cake19,
    cake20:cake20,
    cake21:cake21,
    cake22:cake22,
    cake23:cake23,
    cake24:cake24,
        },
        {
          headers:headers
        }
      );
      setRequireData(response.data)
      showToast();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
      return (
        
        <div >
          <CakesSideBar />
          <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          <div  style={{ marginLeft: 150,marginTop:30 }}>
    <Link to="/cakes&bakes" >
  <div style={{display: 'flex'}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
          <p style={{textAlign:'left', marginTop:20,fontSize:36, color: '#244999'}} >Cakes & Bakes Edit Requirements List</p>
          
             <p style={{color:'red', alignItems:'center'}}>{error}</p>
       
       <div className="row">
         <div className="col-md-3" style={{marginTop:10}}>
           <select
             className="form-control"
             value={location}
             onChange={changelocation}
             style={{color: '#121314',fontSize:20, height:40}}
           >
             <option>Location</option>
             {Places.map((place) => (
               <option value={place}>{place}</option>
             ))}
           </select>
         </div>
        <div className="col-md-3" style={{marginTop:10}}>
           <button  onClick={updateRequirementList} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Update List</button>
         </div>
       </div>
      
       <div className='col-md-6'  style={{marginTop:30, display:'block'}}>
      
      
      <table class="table " >
    <thead>
    <td scope="col" style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Product</td>
          <td scope="col" style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Req Quantity</td>
          
    </thead>
    <tbody>
    <RequirementsTable product='Mini choco vanilla' qty={requireData.cake1} inventory={inventoryData[0].cake1} diff={cake1} setDiff={setCake1} />
    <RequirementsTable product='Mini fruit vanilla' qty={requireData.cake2} inventory={inventoryData[0].cake2} diff={cake2} setDiff={setCake2} />
    <RequirementsTable product='Mini ganache cake' qty={requireData.cake3} inventory={inventoryData[0].cake3} diff={cake3} setDiff={setCake3} />
    <RequirementsTable product='Mini Oreo cake' qty={requireData.cake4} inventory={inventoryData[0].cake4} diff={cake4} setDiff={setCake4} />
    <RequirementsTable product='Mini raffaello cake' qty={requireData.cake5} inventory={inventoryData[0].cake5} diff={cake5} setDiff={setCake5} />
    <RequirementsTable product='Mini Biscoff cake' qty={requireData.cake6} inventory={inventoryData[0].cake6} diff={cake6} setDiff={setCake6} />
    <RequirementsTable product='Pink vanilla cake' qty={requireData.cake7} inventory={inventoryData[0].cake7} diff={cake7} setDiff={setCake7} />
    <RequirementsTable product='Blue vanilla cake' qty={requireData.cake8} inventory={inventoryData[0].cake8} diff={cake8} setDiff={setCake8} />
    <RequirementsTable product='Black forest cake' qty={requireData.cake9} inventory={inventoryData[0].cake9} diff={cake9} setDiff={setCake9} />
    <RequirementsTable product='Chocolate heaven cake' qty={requireData.cake10} inventory={inventoryData[0].cake10} diff={cake10} setDiff={setCake10} />
    <RequirementsTable product='Choco vanilla cake' qty={requireData.cake11} inventory={inventoryData[0].cake11} diff={cake11} setDiff={setCake11} />
    <RequirementsTable product='Ferrero Rocher cake' qty={requireData.cake12} inventory={inventoryData[0].cake12} diff={cake12} setDiff={setCake12} />
    <RequirementsTable product='Mango cake' qty={requireData.cake13} inventory={inventoryData[0].cake13} diff={cake13} setDiff={setCake13} />
    <RequirementsTable product='Nutella cake' qty={requireData.cake14} inventory={inventoryData[0].cake14} diff={cake14} setDiff={setCake14} />
    <RequirementsTable product='Pistachio cake' qty={requireData.cake15} inventory={inventoryData[0].cake15} diff={cake15} setDiff={setCake15} />
    <RequirementsTable product='Fruit paradise cake' qty={requireData.cake16} inventory={inventoryData[0].cake16} diff={cake16} setDiff={setCake16} />
    <RequirementsTable product='Red velvet cake' qty={requireData.cake17} inventory={inventoryData[0].cake17} diff={cake17} setDiff={setCake17} />
    <RequirementsTable product='Strawberry cake' qty={requireData.cake18} inventory={inventoryData[0].cake18} diff={cake18} setDiff={setCake18} />
    <RequirementsTable product='White forest cake' qty={requireData.cake19} inventory={inventoryData[0].cake19} diff={cake19} setDiff={setCake19} />
    <RequirementsTable product='Lotus biscoff cake' qty={requireData.cake20} inventory={inventoryData[0].cake20} diff={cake20} setDiff={setCake20} />
    <RequirementsTable product='Oreo cake' qty={requireData.cake21} inventory={inventoryData[0].cake21} diff={cake21} setDiff={setCake21} />
    <RequirementsTable product='Only berries cake' qty={requireData.cake22} inventory={inventoryData[0].cake22} diff={cake22} setDiff={setCake22} />
    <RequirementsTable product='Fruitful cake' qty={requireData.cake23} inventory={inventoryData[0].cake23} diff={cake23} setDiff={setCake23} />
    <RequirementsTable product='Chocolate overload cake' qty={requireData.cake24} inventory={inventoryData[0].cake24} diff={cake24} setDiff={setCake24} />
    <RequirementsTable product='Mango cupcakes' qty={requireData.cup1} inventory={inventoryData[0].cup1} diff={cup1} setDiff={setCup1} />
    <RequirementsTable product='Lotus Biscoff cupcakes' qty={requireData.cup2} inventory={inventoryData[0].cup2} diff={cup2} setDiff={setCup2} />
    <RequirementsTable product='Rainbow cupcakes' qty={requireData.cup3} inventory={inventoryData[0].cup3} diff={cup3} setDiff={setCup3} />
    <RequirementsTable product='Blondie cupcakes' qty={requireData.cup4} inventory={inventoryData[0].cup4} diff={cup4} setDiff={setCup4} />
    <RequirementsTable product='Pink vanilla cupcakes' qty={requireData.cup5} inventory={inventoryData[0].cup5} diff={cup5} setDiff={setCup5} />
    <RequirementsTable product='Blue vanilla cupcakes' qty={requireData.cup6} inventory={inventoryData[0].cup6} diff={cup6} setDiff={setCup6} />
    <RequirementsTable product='Pistachio cupcakes' qty={requireData.cup7} inventory={inventoryData[0].cup7} diff={cup7} setDiff={setCup7} />
    <RequirementsTable product='Oreo cupcakes' qty={requireData.cup8} inventory={inventoryData[0].cup8} diff={cup8} setDiff={setCup8} />
    <RequirementsTable product='Ferrero cupcakes' qty={requireData.cup9} inventory={inventoryData[0].cup9} diff={cup9} setDiff={setCup9} />
    <RequirementsTable product='Black forest cupcakes' qty={requireData.cup10} inventory={inventoryData[0].cup10} diff={cup10} setDiff={setCup10} />
    <RequirementsTable product='Brownie cupcakes' qty={requireData.cup11} inventory={inventoryData[0].cup11} diff={cup11} setDiff={setCup11} />
    <RequirementsTable product='Red velvet cupcakes' qty={requireData.cup12} inventory={inventoryData[0].cup12} diff={cup12} setDiff={setCup12} />
    <RequirementsTable product='Carrot cupcakes' qty={requireData.cup13} inventory={inventoryData[0].cup13} diff={cup13} setDiff={setCup13} />
    <RequirementsTable product='Mango slices' qty={requireData.slice1} inventory={inventoryData[0].slice1} diff={slice1} setDiff={setSlice1} />
    <RequirementsTable product='Biscoff slices' qty={requireData.slice2} inventory={inventoryData[0].slice2} diff={slice2} setDiff={setSlice2} />
    <RequirementsTable product='Strawberry slices' qty={requireData.slice3} inventory={inventoryData[0].slice3} diff={slice3} setDiff={setSlice3} />
    <RequirementsTable product='Pistachio slices' qty={requireData.slice4} inventory={inventoryData[0].slice4} diff={slice4} setDiff={setSlice4} />
    <RequirementsTable product='Oreo slices' qty={requireData.slice5} inventory={inventoryData[0].slice5} diff={slice5} setDiff={setSlice5} />
    <RequirementsTable product='Black forest slices' qty={requireData.slice6} inventory={inventoryData[0].slice6} diff={slice6} setDiff={setSlice6} />
    <RequirementsTable product='Ferrero slices' qty={requireData.slice7} inventory={inventoryData[0].slice7} diff={slice7} setDiff={setSlice7} />
    <RequirementsTable product='Truffle slices' qty={requireData.slice8} inventory={inventoryData[0].slice8} diff={slice8} setDiff={setSlice8} />
    <RequirementsTable product='Red velvet slices' qty={requireData.slice9} inventory={inventoryData[0].slice9} diff={slice9} setDiff={setSlice9} />
    <RequirementsTable product='Carrot slices' qty={requireData.slice10} inventory={inventoryData[0].slice10} diff={slice10} setDiff={setSlice10} />
    <RequirementsTable product='Lemon & blueberry slices' qty={requireData.slice11} inventory={inventoryData[0].slice11} diff={slice11} setDiff={setSlice11} /> 
    <RequirementsTable product='Truffle & orange slices' qty={requireData.slice12} inventory={inventoryData[0].slice12} diff={slice12} setDiff={setSlice12} />
    <RequirementsTable product='Rainbow slices' qty={requireData.slice13} inventory={inventoryData[0].slice13} diff={slice13} setDiff={setSlice13} />
    
   </tbody>
   </table>
     
      
      </div>
    </div>
        </div>
      );
};

export default RequirementsList;
