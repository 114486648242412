import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import login from "./assets/login.png";

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState("");
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  useEffect(() => {
    if(isAuthenticated){
      history('/home'); 
    }
  }, []); 

  const handleLogin = async() => {
    if(!username)
    {
      setError('Please enter the Username')
      return
    }
    if(!password)
    {
      setError('Please enter the Passwoord')
      return
    }
    try {
      const username1 = 'blupace24';
        const password1 = 'LoginPassword@';
        const base64Credentials = btoa(`${username1}:${password1}`);
        const headers = {
          'Authorization': `Basic ${base64Credentials}`,
          
        };
      const response = await axios.post("http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/admin/login",
        {
          username: username,
          password: password,
        },
        {
          headers:headers
        }
      );
      localStorage.setItem('username', username);
      localStorage.setItem('isLoggedIn', true);
      history('/home'); 
      setError('');
      console.log('Login Successfull****',response.data)
    } 
    catch (error) {
      console.error("Error fetching data:", error);
      if(error.message == "Request failed with status code 500"){
        setError("Wrong password or username!")
      }
    else{
      setError("Something went wrong! Please try  again")
    }
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6 ' >
      <img src={login} alt="Description of the image" style={{width:'100%', height:570,overflow:'hidden', display:'block'}}/>
      </div>
      <div className='col-md-6' style={{alignContent:'center', justifyItems:'center',paddingBottom:0, paddingTop:0, padding:150, }}>
      <div style={{lineHeight:0.5, display:'flex', flexDirection:'column', }}>
      <h2 style={{textAlign:'left', color:'#244999'}}>LOGIN </h2>
      <p style={{marginTop:'10px', color:'red', fontSize:16,textAlign:'left', marginBottom:20}}>{error}</p>
      
        <p style={{textAlign:'left', color:'#7E7E7E'}}>Username</p>
        <input style={{width:200,height:30, color:'#000000'}} type="text" value={username} onChange={(e) => setUsername(e.target.value)}  />
      
        <p style={{textAlign:'left', marginTop:30,color:'#7E7E7E'}}>Password</p>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} style={{width:200,height:30}} />
        <br />
        <button type="button" onClick={handleLogin} style={{backgroundColor:'#048E5B', marginTop:20, width:200, borderWidth:0, borderRadius:5, height:40,color:'white'}}>Login Now</button>
        </div>
        </div>
    </div>
  );
}

export default LoginPage;
