import React, { useState } from 'react';
import axios from 'axios';

const TableBodyElement =({data,isChecked, setIsChecked, isSubmit})=>{
  
    return(
        <div>
      
          {`£${data}`}
       
      </div>
    )
}

export default TableBodyElement;