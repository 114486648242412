import TableBody from "./VerificationSection1Table"

const CashTable =({data})=>{
    return(
        <div >
        <p style={{textAlign:'left', marginTop:10, color:'#048E5B', fontSize:30}}>Cash</p>
        <table class="table">
            <thead>
                <tr>

                    <td scope="col" style={{ fontSize: 12, color: '#476192', borderWidth: 0 }}>Currency in GBP</td>
                    <td scope="col" style={{ fontSize: 12, color: '#476192', borderWidth: 0 }}>QTY</td>
                    <td scope="col" style={{ fontSize: 12, color: '#476192', borderWidth: 0 }}>Total</td>
                </tr>
            </thead>
            <TableBody data={data} />
        </table>
        </div>
    )
}

export default CashTable;