import React from 'react';
import moment from 'moment';

const CakesBody = ({ contents, setError }) => {
  const timeTaken = (date)=>{
  const formattedTime = moment(date).format('HH:mm:ss');
    return formattedTime;
  }
  return (
    <tbody>
           
        {contents.map((content) => (
           <tr key={content._id}>
           <td>{content.store}</td>
          <td>{content.dateFormat}</td>
          <td>{timeTaken(content.date)}</td>
          <td>-</td>
          <td>{content.cake1}</td>
          <td>{content.cake2}</td>
          <td>{content.cake3}</td>
          <td>{content.cake4}</td>
          <td>{content.cake5}</td>
          <td>{content.cake6}</td>
          <td>{content.cake7}</td>
          <td>{content.cake8}</td>
          <td>{content.cake9}</td>
          <td>{content.cake10}</td>
          <td>{content.cake11}</td>
          <td>{content.cake12}</td>
          <td>{content.cake13}</td>
          <td>{content.cake14}</td>
          <td>{content.cake15}</td>
          <td>{content.cake16}</td>
          <td>{content.cake17}</td>
          <td>{content.cake18}</td>
          <td>{content.cake19}</td>
          <td>{content.cake20}</td>
          <td>{content.cake21}</td>
          <td>{content.cake22}</td>
          <td>{content.cake23}</td>
          <td>{content.cake24}</td>
          <td>-</td>
          <td>{content.slice1}</td>
          <td>{content.slice2}</td>
          <td>{content.slice3}</td>
          <td>{content.slice4}</td>
          <td>{content.slice5}</td>
          <td>{content.slice6}</td>
          <td>{content.slice7}</td>
          <td>{content.slice8}</td>
          <td>{content.slice9}</td>
          <td>{content.slice10}</td>
          <td>{content.slice11}</td>
          <td>{content.slice12}</td>
          <td>{content.slice13}</td>
          <td>-</td>
          <td>{content.cup1}</td>
          <td>{content.cup2}</td>
          <td>{content.cup3}</td>
          <td>{content.cup4}</td>
          <td>{content.cup5}</td>
          <td>{content.cup6}</td>
          <td>{content.cup7}</td>
          <td>{content.cup8}</td>
          <td>{content.cup9}</td>
          <td>{content.cup10}</td>
          <td>{content.cup11}</td>
          <td>{content.cup12}</td>
          <td>{content.cup13}</td>
          <td>-</td>
          <td>{content.loaf1}</td>
          <td>{content.loaf2}</td>
          <td>{content.loaf3}</td>
          <td>-</td>
          <td>{content.brownie1}</td>
          <td>{content.brownie2}</td>
          <td>{content.brownie3}</td>
          <td>{content.brownie4}</td>
          <td>-</td>
          <td>{content.biscuit1}</td>
          <td>{content.biscuit2}</td>
          <td>-</td>
          <td>{content.hot1}</td>
          <td>{content.hot2}</td>
          <td>{content.hot3}</td>
          <td>{content.hot4}</td>
          <td>{content.hot5}</td>
          <td>{content.hot6}</td>
          <td>{content.hot7}</td>
          <td>{content.hot8}</td>
          <td>{content.hot9}</td>
          <td>{content.hot10}</td>
          <td>-</td>
          <td>{content.drink1}</td>
          <td>{content.drink2}</td>
          <td>{content.drink3}</td>
          <td>{content.drink4}</td>
          <td>{content.drink5}</td>
          <td>{content.drink6}</td>
          <td>{content.drink7}</td>
          <td>{content.drink8}</td>
          <td>{content.drink9}</td>
          <td>{content.drink10}</td>
          <td>{content.drink11}</td>
          <td>{content.drink12}</td>
          <td>{content.drink13}</td>
          <td>{content.drink14}</td>
          <td>{content.drink15}</td>
          <td>-</td>
          <td>{content.cookie1}</td>
          <td>{content.cookie2}</td>
          <td>{content.cookie3}</td>
          <td>{content.cookie4}</td>
          <td>-</td>
          <td>{content.chocolate1}</td>
          <td>{content.chocolate2}</td>
          <td>{content.chocolate3}</td>
          <td>{content.chocolate4}</td>
          <td>{content.chocolate5}</td>
          <td>{content.chocolate6}</td>
          <td>{content.chocolate7}</td>
          <td>{content.chocolate8}</td>
          <td>-</td>
          <td>{content.topping1}</td>
          <td>{content.topping2}</td>
          <td>{content.topping3}</td>
          <td>{content.topping4}</td>
          <td>{content.topping5}</td>
          <td>{content.topping6}</td>
          <td>{content.topping7}</td>
          <td>{content.topping8}</td>
          <td>{content.topping9}</td>
          <td>{content.topping10}</td>
          <td>{content.topping11}</td>
          <td>{content.topping12}</td>
          <td>{content.topping13}</td>
          <td>{content.topping14}</td>
          <td>{content.topping15}</td>
          <td>{content.topping16}</td>
          <td>{content.topping17}</td>
          <td>-</td>
          <td>{content.milkshake1}</td>
          <td>{content.milkshake2}</td>
          <td>{content.milkshake3}</td>
          <td>{content.milkshake4}</td>
          <td>{content.milkshake5}</td>
          <td>{content.milkshake6}</td>
          <td>{content.milkshake7}</td>
          <td>-</td>
          <td>{content.essential1}</td>
          <td>{content.essential2}</td>
          <td>{content.essential3}</td>
          <td>{content.essential4}</td>
          <td>{content.essential5}</td>
          <td>{content.essential6}</td>
          <td>{content.essential7}</td>
          <td>{content.essential8}</td>
          <td>{content.essential9}</td>
          <td>{content.essential10}</td>
          <td>{content.essential11}</td>
          <td>{content.essential12}</td>
          <td>{content.essential13}</td>
          <td>{content.essential14}</td>
          <td>{content.essential15}</td>
          <td>{content.essential16}</td>
          <td>{content.essential17}</td>
          <td>{content.essential18}</td>
          <td>{content.essential19}</td>
          <td>{content.essential20}</td>
          <td>{content.essential21}</td>
          <td>-</td>
          <td>{content.sponge1}</td>
          <td>{content.sponge2}</td>
          <td>{content.sponge3}</td>
          <td>{content.sponge4}</td>
          <td>{content.sponge5}</td>
          <td>{content.sponge6}</td>
          <td>{content.sponge7}</td>
          <td>{content.sponge8}</td>
          <td>{content.sponge9}</td>
          <td>{content.sponge10}</td>
          <td>{content.sponge11}</td>
          <td>{content.sponge12}</td>
          <td>{content.sponge13}</td>
          <td>{content.sponge14}</td>
          <td>{content.sponge15}</td>
          <td>{content.sponge16}</td>
          <td>{content.sponge17}</td>
          <td>{content.sponge18}</td>
          <td>{content.sponge19}</td>
          <td>{content.sponge20}</td>
          <td>{content.sponge21}</td>
          <td>{content.sponge22}</td>
          <td>{content.sponge23}</td>
          <td>{content.sponge24}</td>
          <td>{content.sponge25}</td>
          <td>{content.sponge26}</td>
          <td>{content.sponge27}</td>
          <td>{content.sponge28}</td>
          <td>{content.sponge29}</td>
          <td>{content.sponge30}</td>
          <td>{content.sponge31}</td>
          <td>{content.sponge32}</td>
          <td>-</td>
          <td>{content.board1}</td>
          <td>{content.board2}</td>
          <td>{content.board3}</td>
          <td>{content.board4}</td>
          <td>{content.board5}</td>
          <td>{content.board6}</td>
          <td>{content.board7}</td>
          <td>{content.board8}</td>
          <td>{content.board9}</td>
          <td>{content.board10}</td>
          <td>{content.other}</td>
          <td>{content.submittedBy}</td>
          </tr>
        ))}
     
    </tbody>
  );
};

export default CakesBody;




