import Tablerow from "./TableRow";

const TableBody =({data})=>{
    return(
        <tbody>
           <Tablerow item='£50' qty={data.n50} total={data.n50t}/>
           <Tablerow item='£20' qty={data.n20} total={data.n20t}/>
           <Tablerow item='£10' qty={data.n10} total={data.n10t}/> 
           <Tablerow item='£5' qty={data.n5} total={data.n5t}/>
           <Tablerow item='£2' qty={data.n2} total={data.n2t}/>
           <Tablerow item='£1' qty={data.n1} total={data.n1t}/>
           <Tablerow item='50p' qty={data.n50p} total={data.n50pt}/>
           <Tablerow item='20p' qty={data.n20p} total={data.n20pt}/> 
           <Tablerow item='10p' qty={data.n10p} total={data.n10pt}/>
           <Tablerow item='5p' qty={data.n5p} total={data.n5pt}/>
           <Tablerow item='2p' qty={data.n2p} total={data.n2pt}/>
           <Tablerow item='1p' qty={data.n1p} total={data.n1pt}/>
           <Tablerow item='Total cash in Till' qty={'-'} total={data.tillcash}/>
          
        </tbody>
    )
}


export default TableBody;
