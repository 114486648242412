import React from 'react';

const SaleReportBody = ({ contents, setError }) => {
  return (
    <tbody>
           
        {contents.map((content) => (
           <tr key={content._id} >
         <td  style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{content.store}</td>
          <td  style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{content.dateFormat}</td>
          <td  style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{`£${content.cashmanag}`}</td>
          <td  style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{`£${content.storesales}`}</td>
          <td  style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{`£${content.onlineTotal}`}</td>
          </tr>
        ))}
     
    </tbody>
  );
};

export default SaleReportBody;




