const PurchaseTable=({data})=>{
    return(
        <>
         <thead>
            <tr>
            <td scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Purchase</td>
          <td scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Amount</td>
            </tr>
          </thead>
          <tbody>
      
      {data.map((purchase) => (
       <tr>
        <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{purchase.purchase}</td>
        <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}} >{`£${purchase.amount}`}</td>
        </tr>
      ))}
   
  </tbody>
        </>
    )
}
export default PurchaseTable;