import { useState,useRef, useEffect } from "react";
import './styles.css'; 

const SaleReportRow =({product, stock, inventory,sales,inventory2, diff, setDiff,wastage, id})=>{
  const sum = stock
      .filter(item => item.product_id === id)
      .reduce((acc, item) => acc + item.pickup_qty, 0);
  const dup = parseFloat(sum) + parseFloat(inventory) - parseFloat(sales)
  const getClassName = (value) => {
    if (parseFloat(value) === dup) return 'green';
    if (parseFloat(value) < dup) return 'red';
    return 'orange';
};
  const handleInputChange = (e, setDiff) => {
    setDiff(e.target.value);
  };
  useEffect(()=>{
    setDiff(dup)
  },[dup])
  
    return(
        <tr key={product}>
      <td scope="row" style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1'}} >{product}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{inventory}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{sum}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{sales}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}> {dup}</td>
       <td  className={getClassName(inventory2)} style={{color:'#1E4388', fontSize:14,}}>{inventory2}</td>
       <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{wastage}</td>

      
        </tr>
    )
}
export default SaleReportRow;