import React,{useState,useEffect,useRef, } from 'react';
import TableBodyInventory from "./TableBodyInventory";
import TableheaderInventory from "./TableheaderInventory";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import excel from "./assets/excel.png";


const InitialHeaders = [{num:'*', header:'Location', id:'store'},{num:'*', header:'Date', id:'dateFormat'},]                
// const CakesHeaders = [
//   {header:'CAKES', id:'CAKES',num:'*'},{header:'Mini choco vanilla', id:'cake1',num:1},{header:'Mini fruit vanilla', id:'cake2',num:2},{header:'Mini ganache', id:'cake3',num:3},{header:'Mini Oreo', id:'cake4',num:4},{header:'Mini Raffaello', id:'cake5',num:5},{header:'Mini Biscoff', id:'cake6',num:6},{header:'Pink vanilla', id:'cake7',num:7,},{num:8,header:'Blue vanilla', id:'cake8'},{num:9,header:'Black forest', id:'cake9'},{num:10,header:'Chocolate heaven', id:'cake10'}, {header:'Choco vanilla', id:'cake11',num:11}, {header:'Ferrero Rocher', id:'cake12',num:12},{header:'Mango cake', id:'cake13',num:13}, {header:'Nutella cake', id:'cake14',num:14},{header:'Pistachio cake', id:'cake15',num:15},{header:'Fruit paradise', id:'cake16',num:16},{header:'Red Velvet', id:'cake17',num:17}, {num:18,header:'Strawberry cake', id:'cake18'},{num:19,header:'White forest cake', id:'cake19'},{num:20,header:'Lotus Biscoff cake', id:'cake20'}, {header:'Oreo cake', id:'cake21',num:21}, {header:'Only berries cake', id:'cake22',num:22},{header:'Fruitful', id:'cake23',num:23}, {header:'Ch overload', id:'cake24',num:24},
//     {header:'SLICES', id:'CAKES',num:'*'},{header:'Mango', id:'slice1',num:1},{header:'Lotus Biscoff', id:'slice2',num:2},{header:'Strawberry', id:'slice3',num:3},{header:'Pistachio', id:'slice4',num:4},{header:'Oreo', id:'slice5',num:5},{header:'Black forest', id:'slice6',num:6},{header:'Ferrero Rocher', id:'slice7',num:7,},{num:8,header:'Truffle treat', id:'slice8'},{num:9,header:'Red velvet', id:'slice9'},{num:10,header:'Carrot', id:'slice10'}, {header:'Lemon berry', id:'slice11',num:11}, {header:'Tangy truffle', id:'slice12',num:12},{header:'Rainbow', id:'slice13',num:13}, 
//     {header:'CUP CAKES', id:'CAKES',num:'*'},{header:'Mango', id:'cup1',num:1},{header:'Lotus Biscoff', id:'cup2',num:2},{header:'Rainbow', id:'cup3',num:3},{header:'Blondie', id:'cup4',num:4},{header:'Pink vanilla', id:'cup5',num:5},{header:'Blue vanilla', id:'cup6',num:6},{header:'Pistachio', id:'cup7',num:7,},{num:8,header:'Oreo', id:'cup8'},{num:9,header:'Ferrero', id:'cup9'},{num:10,header:'Black forest', id:'cup10'}, {header:'Brownie', id:'cup11',num:11}, {header:'Red velvet', id:'cup12',num:12},{header:'Carrot', id:'cup13',num:13},
//     {header:'DRINKS', id:'CAKES',num:'*'},{header:'Red bull', id:'drink1',num:1},{header:'Coke 330 ml', id:'drink2',num:2},{header:'Diet coke 330 ml', id:'drink3',num:3},{header:'Coke zero 330 ml', id:'drink4',num:4},{header:'Pepsi max 330 ml', id:'drink5',num:5},{header:'Ribena', id:'drink6',num:6},{header:'Coconut water', id:'drink7',num:7,},{num:8,header:'Lucozade', id:'drink8'},{num:9,header:'Fanta 330 ml', id:'drink9'},{num:10,header:'7up 330 ml', id:'drink10'},{header:'Evian water', id:'drink11',num:11},{header:'Starbucks frappuccino', id:'drink12',num:12},{header:'Tropical orange juice', id:'drink13',num:13},{header:'Tropical apple juice', id:'drink14',num:14},{header:'Tropical mixed fruit juice', id:'drink15',num:15}, 
//     {header:'LOAF CAKES', id:'CAKES',num:'*'},{header:'Banana', id:'loaf1',num:1},{header:'Marble', id:'loaf2',num:2},{header:'Madeira', id:'loaf3',num:3},
//     {header:'HOT EATS', id:'CAKES',num:'*'},{num:1, header:'Spicy veg', id:'hot1'},{num:2,header:'Veg samosa', id:'hot2'},{num:3,header:'Paneer', id:'hot3'},{num:4,header:'Potato', id:'hot4'},{header:'Gobi roll', id:'hot5',num:5,},{num:6,header:'Cheese & onion', id:'hot6'},{num:7,header:'Cheese & Jalapenos', id:'hot7'}, {num:8,header:'Spicy chicken', id:'hot8'},{num:9,header:'Creamy chicken', id:'hot9'},{num:10, header:'Spicy lamb', id:'hot10'},
//     {header:'COOKIES', id:'CAKES',num:'*'},{header:'Red velvet cookies', id:'cookie1',num:1},{header:'Chocolate chip cookies', id:'cookie2',num:2},{header:'Cranberry cookies', id:'cookie3',num:3},{header:'Triple chocolate chip cookies', id:'cookie4',num:4},    
//     {header:'CHOCOLATES', id:'CAKES',num:'*'},{header:'Dairy milk', id:'chocolate1',num:1},{header:'Kitkat', id:'chocolate2',num:2},{header:'Kinder beuno white', id:'chocolate3',num:3},{header:'Kinder beuno dark', id:'chocolate4',num:4},{header:'Maltesers', id:'chocolate5',num:5},{header:'Ferrero rocher', id:'chocolate6',num:6},{header:'Oreo biscuits', id:'chocolate7',num:7,},{num:8,header:'Lotus Biscoff Biscuits', id:'chocolate8'},
//     {header:'CAKE TOPPINGS', id:'CAKES',num:'*'},{header:'Chocolate flakes', id:'topping1',num:1},{header:'Cherries', id:'topping2',num:2},{header:'Barbara décor/filgranes', id:'topping3',num:3},{header:'Zebra sticks', id:'topping4',num:4},{header:'White sugar pearls', id:'topping5',num:5},{header:'Gold sugar pearls', id:'topping6',num:6},{header:'Pink sugar pearls', id:'topping7',num:7,},{num:8,header:'Silver sugar pearls', id:'topping8'},{num:9,header:'Rainbow sprinkles', id:'topping9'},{num:10,header:'Chocolate vermicelli', id:'topping10'}, {header:'Chocolate curls', id:'topping11',num:11}, {header:'Marble curls', id:'topping12',num:12},{header:'White chocolate curls', id:'topping13',num:13}, {header:'Strawberry curls', id:'topping14',num:14},{header:'Dark chocolate shavings', id:'topping15',num:15},{header:'Margarine', id:'topping16',num:16},{header:'Cocoa powder', id:'topping17',num:17},
//     {header:'MILKSHAKES/SMOOTHIES', id:'CAKES',num:'*'},{header:'Strawberry glory', id:'milkshake1',num:1},{header:'Top banana', id:'milkshake2',num:2},{header:'Livin la vida Cocoa', id:'milkshake3',num:3},{header:'Va va vanilla', id:'milkshake4',num:4},{header:'Strawberry split', id:'milkshake5',num:5},{header:'Berry go round', id:'milkshake6',num:6},{header:'Pash n Shoot', id:'milkshake7',num:7},
//     {header:'ESSENTIALS', id:'CAKES',num:'*'},{header:'Antibacterial spray', id:'essential1',num:1},{header:'Glass cleaner', id:'essential2',num:2},{header:'Plastic forks', id:'essential3',num:3},{header:'Plastic spoons', id:'essential4',num:4},{header:'Napkins', id:'essential5',num:5},{header:'Plates', id:'essential6',num:6},{header:'Bags', id:'essential7',num:7,},{num:8,header:'Washing liquid', id:'essential8'},{num:9,header:'Floor cleaner', id:'essential9'},{num:10,header:'Blue rolls', id:'essential10'}, {header:'Gloves', id:'essential11',num:11}, {header:'Slice wraps', id:'essential12',num:12},{header:'Food hygiene labels', id:'essential13',num:13}, {header:'Hairnets', id:'essential14',num:14},{header:'Toilet rolls', id:'essential15',num:15},{header:'Baking paper', id:'essential16',num:16},{header:'Piping bags', id:'essential17',num:17}, {num:18,header:'Order books', id:'essential18'},{num:19,header:'Bin bags', id:'essential19'},{num:20,header:'Till rolls', id:'essential20'}, {header:'Card machine rolls', id:'essential21',num:21},
//     {header:'BROWNIES', id:'CAKES',num:'*'},{header:'Chocolate', id:'brownie1',num:1},{header:'Oreo', id:'brownie2',num:2},{header:'Lotus Biscoff', id:'brownie3',num:3},{header:'White chocolate', id:'brownie4',num:4},     
//     {header:'BISCUITS', id:'CAKES',num:'*'},{header:'Almond', id:'biscuit1',num:1},{header:'Tea', id:'biscuit2',num:2},
//     {header:'SPONGES', id:'CAKES',num:'*'},{header:'Chocolate cupcakes', id:'sponge1',num:1},{header:'Vanilla cupcakes', id:'sponge2',num:2},{header:'Red velvet cupcakes', id:'sponge3',num:3},{header:'6" eggles vanilla', id:'sponge4',num:4},{header:'6" eggless chocolate', id:'sponge5',num:5},{header:'8" eggless vanilla', id:'sponge6',num:6},{header:'8" eggless chocolate', id:'sponge7',num:7,},{num:8,header:'8" vanilla with egg', id:'sponge8'},{num:9,header:'8" chocolate with egg', id:'sponge9'},{num:10,header:'8" red velvet', id:'sponge10'}, {header:'10" eggless vanilla', id:'sponge11',num:11}, {header:'10" eggless chocolate', id:'sponge12',num:12},{header:'10" vanilla with egg', id:'sponge13',num:13}, {header:'10" chocolate with egg', id:'sponge14',num:14},{header:'10" red velvet', id:'sponge15',num:15},{header:'12" eggless vanilla', id:'sponge16',num:16},{header:'12" eggless chocolate', id:'sponge17',num:17}, {num:18,header:'12" vanilla with egg', id:'sponge18'},{num:19,header:'12" chocolate with egg', id:'sponge19'},{num:20,header:'14" eggless vanilla', id:'sponge20'}, {header:'14" eggless chocolate', id:'sponge21',num:21}, {header:'14" vanilla with egg', id:'sponge22',num:22},{header:'14" chocolate with egg', id:'sponge23',num:23}, {header:'16" eggless vanilla', id:'sponge24',num:24},{header:'16" eggless chocolate', id:'sponge25',num:25},{header:'16" vanilla with egg', id:'sponge26',num:26},{header:'16" chocolate with egg', id:'sponge27',num:27,},{num:28,header:'16X28 eggless vanilla', id:'sponge28'},{num:29,header:'16X28 eggless chocolate', id:'sponge29'},{num:30,header:'16X28 vanilla with egg', id:'sponge30'}, {header:'16X28 chocolate with egg', id:'sponge31',num:31}, {header:'16X28 red velvet', id:'sponge32',num:32},
//     {header:'BROADS', id:'CAKES',num:'*'},{header:'8" round boards', id:'board1',num:1},{header:'10" round boards', id:'board2',num:2},{header:'12" round boards', id:'board3',num:3},{header:'14" round boards', id:'board4',num:4},{header:'16" round boards', id:'board5',num:5},{header:'16X28 boards', id:'board6',num:6},{header:'10" square boards', id:'board7',num:7,},{num:8,header:'12" square boards', id:'board8'},{num:9,header:'14" square boards', id:'board9'},{num:10,header:'16" square boards', id:'board10'},
    
// ]
const CakesHeaders = [{header:'Mini choco vanilla', id:'cake1',num:1},{header:'Mini fruit vanilla', id:'cake2',num:2},{header:'Mini ganache', id:'cake3',num:3},{header:'Mini Oreo', id:'cake4',num:4},{header:'Mini Raffaello', id:'cake5',num:5},{header:'Mini Biscoff', id:'cake6',num:6},{header:'Pink vanilla', id:'cake7',num:7,},{num:8,header:'Blue vanilla', id:'cake8'},{num:9,header:'Black forest', id:'cake9'},{num:10,header:'Chocolate heaven', id:'cake10'}, {header:'Choco vanilla', id:'cake11',num:11}, {header:'Ferrero Rocher', id:'cake12',num:12},{header:'Mango cake', id:'cake13',num:13}, {header:'Nutella cake', id:'cake14',num:14},{header:'Pistachio cake', id:'cake15',num:15},{header:'Fruit paradise', id:'cake16',num:16},{header:'Red Velvet', id:'cake17',num:17}, {num:18,header:'Strawberry cake', id:'cake18'},{num:19,header:'White forest cake', id:'cake19'},{num:20,header:'Lotus Biscoff cake', id:'cake20'}, {header:'Oreo cake', id:'cake21',num:21}, {header:'Only berries cake', id:'cake22',num:22},{header:'Fruitful', id:'cake23',num:23}, {header:'Ch overload', id:'cake24',num:24},]
const SliceHeaders =[{header:'Mango', id:'slice1',num:1},{header:'Lotus Biscoff', id:'slice2',num:2},{header:'Strawberry', id:'slice3',num:3},{header:'Pistachio', id:'slice4',num:4},{header:'Oreo', id:'slice5',num:5},{header:'Black forest', id:'slice6',num:6},{header:'Ferrero Rocher', id:'slice7',num:7,},{num:8,header:'Truffle treat', id:'slice8'},{num:9,header:'Red velvet', id:'slice9'},{num:10,header:'Carrot', id:'slice10'}, {header:'Lemon berry', id:'slice11',num:11}, {header:'Tangy truffle', id:'slice12',num:12},{header:'Rainbow', id:'slice13',num:13},   ]
const CupcakesHeaders =[{header:'Mango', id:'cup1',num:1},{header:'Lotus Biscoff', id:'cup2',num:2},{header:'Rainbow', id:'cup3',num:3},{header:'Blondie', id:'cup4',num:4},{header:'Pink vanilla', id:'cup5',num:5},{header:'Blue vanilla', id:'cup6',num:6},{header:'Pistachio', id:'cup7',num:7,},{num:8,header:'Oreo', id:'cup8'},{num:9,header:'Ferrero', id:'cup9'},{num:10,header:'Black forest', id:'cup10'}, {header:'Brownie', id:'cup11',num:11}, {header:'Red velvet', id:'cup12',num:12},{header:'Carrot', id:'cup13',num:13},  ]
const DrinksHeaders =[{header:'Red bull', id:'drink1',num:1},{header:'Coke 330 ml', id:'drink2',num:2},{header:'Diet coke 330 ml', id:'drink3',num:3},{header:'Coke zero 330 ml', id:'drink4',num:4},{header:'Pepsi max 330 ml', id:'drink5',num:5},{header:'Ribena', id:'drink6',num:6},{header:'Coconut water', id:'drink7',num:7,},{num:8,header:'Lucozade', id:'drink8'},{num:9,header:'Fanta 330 ml', id:'drink9'},{num:10,header:'7up 330 ml', id:'drink10'},{header:'Evian water', id:'drink11',num:11},{header:'Starbucks frappuccino', id:'drink12',num:12},{header:'Tropical orange juice', id:'drink13',num:13},{header:'Tropical apple juice', id:'drink14',num:14},{header:'Tropical mixed fruit juice', id:'drink15',num:15},    ]
const LoafHeaders = [{header:'Banana', id:'loaf1',num:1},{header:'Marble', id:'loaf2',num:2},{header:'Madeira', id:'loaf3',num:3},]
const HotEatsHeaders = [{num:1, header:'Spicy veg', id:'hot1'},{num:2,header:'Veg samosa', id:'hot2'},{num:3,header:'Paneer', id:'hot3'},{num:4,header:'Potato', id:'hot4'},{header:'Gobi roll', id:'hot5',num:5,},{num:6,header:'Cheese & onion', id:'hot6'},{num:7,header:'Cheese & Jalapenos', id:'hot7'}, {num:8,header:'Spicy chicken', id:'hot8'},{num:9,header:'Creamy chicken', id:'hot9'},{num:10, header:'Spicy lamb', id:'hot10'},]
const CookiesHeaders =[{header:'Red velvet cookies', id:'cookie1',num:1},{header:'Chocolate chip cookies', id:'cookie2',num:2},{header:'Cranberry cookies', id:'cookie3',num:3},{header:'Triple chocolate chip cookies', id:'cookie4',num:4},              ]
const ChocolatesHeaders =[{header:'Dairy milk', id:'chocolate1',num:1},{header:'Kitkat', id:'chocolate2',num:2},{header:'Kinder beuno white', id:'chocolate3',num:3},{header:'Kinder beuno dark', id:'chocolate4',num:4},{header:'Maltesers', id:'chocolate5',num:5},{header:'Ferrero rocher', id:'chocolate6',num:6},{header:'Oreo biscuits', id:'chocolate7',num:7,},{num:8,header:'Lotus Biscoff Biscuits', id:'chocolate8'},]
const ToppingHeaders =[{header:'Chocolate flakes', id:'topping1',num:1},{header:'Cherries', id:'topping2',num:2},{header:'Barbara décor/filgranes', id:'topping3',num:3},{header:'Zebra sticks', id:'topping4',num:4},{header:'White sugar pearls', id:'topping5',num:5},{header:'Gold sugar pearls', id:'topping6',num:6},{header:'Pink sugar pearls', id:'topping7',num:7,},{num:8,header:'Silver sugar pearls', id:'topping8'},{num:9,header:'Rainbow sprinkles', id:'topping9'},{num:10,header:'Chocolate vermicelli', id:'topping10'}, {header:'Chocolate curls', id:'topping11',num:11}, {header:'Marble curls', id:'topping12',num:12},{header:'White chocolate curls', id:'topping13',num:13}, {header:'Strawberry curls', id:'topping14',num:14},{header:'Dark chocolate shavings', id:'topping15',num:15},{header:'Margarine', id:'topping16',num:16},{header:'Cocoa powder', id:'topping17',num:17}, ]
const MilkshakesHeaders =[{header:'Strawberry glory', id:'milkshake1',num:1},{header:'Top banana', id:'milkshake2',num:2},{header:'Livin la vida Cocoa', id:'milkshake3',num:3},{header:'Va va vanilla', id:'milkshake4',num:4},{header:'Strawberry split', id:'milkshake5',num:5},{header:'Berry go round', id:'milkshake6',num:6},{header:'Pash n Shoot', id:'milkshake7',num:7},]
const EssentialsHeaders = [{header:'Antibacterial spray', id:'essential1',num:1},{header:'Glass cleaner', id:'essential2',num:2},{header:'Plastic forks', id:'essential3',num:3},{header:'Plastic spoons', id:'essential4',num:4},{header:'Napkins', id:'essential5',num:5},{header:'Plates', id:'essential6',num:6},{header:'Bags', id:'essential7',num:7,},{num:8,header:'Washing liquid', id:'essential8'},{num:9,header:'Floor cleaner', id:'essential9'},{num:10,header:'Blue rolls', id:'essential10'}, {header:'Gloves', id:'essential11',num:11}, {header:'Slice wraps', id:'essential12',num:12},{header:'Food hygiene labels', id:'essential13',num:13}, {header:'Hairnets', id:'essential14',num:14},{header:'Toilet rolls', id:'essential15',num:15},{header:'Baking paper', id:'essential16',num:16},{header:'Piping bags', id:'essential17',num:17}, {num:18,header:'Order books', id:'essential18'},{num:19,header:'Bin bags', id:'essential19'},{num:20,header:'Till rolls', id:'essential20'}, {header:'Card machine rolls', id:'essential21',num:21}, ]
const SpongesHeader = [{header:'Chocolate cupcakes', id:'sponge1',num:1},{header:'Vanilla cupcakes', id:'sponge2',num:2},{header:'Red velvet cupcakes', id:'sponge3',num:3},{header:'6" eggles vanilla', id:'sponge4',num:4},{header:'6" eggless chocolate', id:'sponge5',num:5},{header:'8" eggless vanilla', id:'sponge6',num:6},{header:'8" eggless chocolate', id:'sponge7',num:7,},{num:8,header:'8" vanilla with egg', id:'sponge8'},{num:9,header:'8" chocolate with egg', id:'sponge9'},{num:10,header:'8" red velvet', id:'sponge10'}, {header:'10" eggless vanilla', id:'sponge11',num:11}, {header:'10" eggless chocolate', id:'sponge12',num:12},{header:'10" vanilla with egg', id:'sponge13',num:13}, {header:'10" chocolate with egg', id:'sponge14',num:14},{header:'10" red velvet', id:'sponge15',num:15},{header:'12" eggless vanilla', id:'sponge16',num:16},{header:'12" eggless chocolate', id:'sponge17',num:17}, {num:18,header:'12" vanilla with egg', id:'sponge18'},{num:19,header:'12" chocolate with egg', id:'sponge19'},{num:20,header:'14" eggless vanilla', id:'sponge20'}, {header:'14" eggless chocolate', id:'sponge21',num:21}, {header:'14" vanilla with egg', id:'sponge22',num:22},{header:'14" chocolate with egg', id:'sponge23',num:23}, {header:'16" eggless vanilla', id:'sponge24',num:24},{header:'16" eggless chocolate', id:'sponge25',num:25},{header:'16" vanilla with egg', id:'sponge26',num:26},{header:'16" chocolate with egg', id:'sponge27',num:27,},{num:28,header:'16X28 eggless vanilla', id:'sponge28'},{num:29,header:'16X28 eggless chocolate', id:'sponge29'},{num:30,header:'16X28 vanilla with egg', id:'sponge30'}, {header:'16X28 chocolate with egg', id:'sponge31',num:31}, {header:'16X28 red velvet', id:'sponge32',num:32},]
const BoardsHeaders =[{header:'8" round boards', id:'board1',num:1},{header:'10" round boards', id:'board2',num:2},{header:'12" round boards', id:'board3',num:3},{header:'14" round boards', id:'board4',num:4},{header:'16" round boards', id:'board5',num:5},{header:'16X28 boards', id:'board6',num:6},{header:'10" square boards', id:'board7',num:7,},{num:8,header:'12" square boards', id:'board8'},{num:9,header:'14" square boards', id:'board9'},{num:10,header:'16" square boards', id:'board10'},]
const BrowniesHeaders =[{header:'Chocolate', id:'brownie1',num:1},{header:'Oreo', id:'brownie2',num:2},{header:'Lotus Biscoff', id:'brownie3',num:3},{header:'White chocolate', id:'brownie4',num:4},              ]
const BiscuitsHeaders = [{header:'Almond', id:'biscuit1',num:1},{header:'Tea', id:'biscuit2',num:2},]

const SingleCakesInventorySheet = ({data}) =>{
    const cakesRef = useRef(null);
   
    const [stock, setStock] = useState(data.length ===0 ?[]:data)
    useEffect(()=>{
        setStock(data.length ===0 ?[]:data)
      },[data])
    return(
        <div>
             <div style={{ border: '1px solid #DCDCDC', padding: 10, marginBottom:20}}>
                    <div style={{ padding: 5, paddingBottom: 0, paddingTop: 0 }}>
                        <div className='row' style={{alignItems:'center',marginBottom:10}}>
                            <div className='col-md-9'>
                        <p style={{ textAlign: 'left', marginTop: 10, color: '#048E5B', fontSize: 25 }}>
                          Cakes</p>
                        </div>
                        <div div className='col-md-3' style={{display:'flex'}}>
                <DownloadTableExcel
                  filename={'Cakes Inventory'}
                  sheet="Inventory"
                  currentTableRef={cakesRef.current}
                >

                  <button style={{fontSize:20,height:40, color:'#FFFFFF',backgroundColor:'#048E5B',borderWidth:0,borderRadius:5, alignItems:'flex-start'}} ><img src={excel} alt="Logo" style={{width:18, height:20}}/> Export excel </button>

                </DownloadTableExcel>
              </div>
                        </div>
                        <table class="table-bordered " ref={cakesRef}>
                        {InitialHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,backgroundColor:'#75757529'}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left', backgroundColor:'#75757529'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,backgroundColor:'#75757529'}}>{content[header['id']]}</td>
                  ))}
              </tr>
                ))}
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>CAKES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {CakesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right', }}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>SLICES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {SliceHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>CUPCAKES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {CupcakesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>DRINKS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {DrinksHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>LOAF CAKES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {LoafHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>HOT EATS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {HotEatsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>BROWNIES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {BrowniesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>BISCUITS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {BiscuitsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>COOKIES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {CookiesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>CHOCOLATES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {ChocolatesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>CAKES TOPPINGS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {ToppingHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>MILKSHAKES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {MilkshakesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>ESSENTIALS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {EssentialsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>SPONGES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {SpongesHeader.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                           <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left'}}>BOARDS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {BoardsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                            
                        
                        </table>
                    </div>
                </div>
                
                
               
        </div>
    )
}
export default SingleCakesInventorySheet;