import CheckBoxInput from "./CheckBoxInput";

const TwocoloumntableRow =({item, amount, })=>{
    return(
        <tr >
      <td scope="row" style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1'}}>{item}</td>
      <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${amount?amount:0}`}</td>
      
      <td style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1'}}></td>
        </tr>
    )
}
export default TwocoloumntableRow;