import { useState,useRef, useEffect } from "react";
import axios from "axios";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import VerificationSection1 from "./VerficationSection1";
import { Link } from "react-router-dom";
import {IoIosArrowDropleftCircle  } from 'react-icons/io';
import Sidebar from "./Sidebar";
import { useNavigate } from 'react-router-dom';
import CakesSideBar from "./CakesSideBar";
import CustomSideBar from "./CustomSideBar";


const Places = [
  "Ilford lane",
  "Leyton",
  "Norwood",
  "Ruislip Manor",
  "South Woodford",
  "Walthamstow"
];
const storeNames=["Cakes & Bakes", "Hyderabad Wala"]
function CakesVerificationScreen() {
  const tableRef = useRef(null);
  const [data, setData] = useState({});
  const [location, setLocation] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [store, setStore] = useState("");
  const [error, setError] = useState("");
  const [duration, setDuration] = useState("");
  const [startdate, setStartdate] = useState("");
  const today = new Date();
  const [selectedstartDate, setSelectedstartDate] = useState(new Date ());
  const [selectedendDate, setSelectedendDate] = useState(new Date ());
  const [visible, setVisible] = useState(false);
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  const handlestartDateChange = (event) => {
    setSelectedstartDate(new Date(event.target.value));
  };
  const changelocation = (event) => {
    setLocation(event.target.value);
   
  };
  const fetchyesterdayData = async () => {
    setError('')
    const formatDate=selectedstartDate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/yesterdayDaySheet",
        {
          store: location,
          dateFormat: formatDate,
          shop:'Cakes & Bakes',
        },
        {
          headers:headers
        }
      );
      if(!(response.data)){
        setData({});
        setError('There is no record for selected date');
        return
      }
      setData(response.data?response.data:{});
      setError('');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const getData = () => {
   
    if(location == '')
    {
      setError('Please select Location!')
      return
    }
    
    fetchyesterdayData();
  };

  return (
    <> 
    <CustomSideBar />
    
  <div style={{ marginLeft: 150 , marginTop:30}}>
  <Link to="/cakes&bakes" >
  <div style={{display: 'flex'}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
    
      <p style={{textAlign:'left', marginTop:20,fontSize:36, color: '#244999', marginBottom:30}}>Cakes & Bakes Verification Sheet</p>
        <p style={{ color: 'red', fontSize:20 }}>{error}</p>

        <div className="row" >
        
          <div className="col-md-3" style={{marginTop:10}}>
            <select
              className="form-control"
              value={location}
              onChange={changelocation}
              style={{color: '#121314',fontSize:20, height:40}}
            >
              <option>Location</option>
              {Places.map((place) => (
                <option value={place}  style={{color: '#121314',fontSize:20, height:40}}>{place}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3" style={{ alignItems:'center', justifyContent:'center',marginLeft:15, marginTop:10}}>
            <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5}}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label style={{textAlign:'center',color:'#6E6E6E'}}>Select Date :</label>
            </div>
            <input
              type="date"
              value={selectedstartDate.toISOString().split('T')[0]}
              onChange={handlestartDateChange}
              style={{ border: 'none' , height:30,marginTop:5,marginBottom:5,color:'#121314', fontSize:20}} />
              </div>
          </div>
          <div className="col-md-3" style={{marginTop:10}}>
            {/* <button onClick={sendDataToServer}>Send Date</button> */}
            <button onClick={getData} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Get Day Summary Data</button>
          </div>


        </div>

        <div style={{ padding: 20}}>
          {data && <VerificationSection1 content={data} setError={setError} />}
          
        </div>
      </div></>
  );
}

export default CakesVerificationScreen;
