import Accordion from 'react-bootstrap/Accordion';

function WastageAccordion({data}) {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ fontSize: '16px' }}> wastages</Accordion.Header>
        <Accordion.Body>
        <thead>
          <tr>
            <th scope="col" >MATERIAL NAME</th>
            <th scope="col" >QTY</th>
            <th scope="col" >REASON</th>
          </tr>
       </thead>
       <tbody>
      
        {data.wastages.map((wastage) => (
         <tr>
          <td >{wastage.item}</td>
          <td >{wastage.quantity}</td>
          <td >{wastage.reason}</td>
          </tr>
        ))}
     
    </tbody>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default WastageAccordion;