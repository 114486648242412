const GoodToGoTable=({data})=>{
    return(
        <>
         <thead>
            <tr>
              <td scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Bag Price</td>
              <td scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Bag Contents</td>
              <td scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Bag ID</td>
            </tr>
          </thead>
          <tbody>
            {data.bagprice1 && <tr>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${data.bagprice1}`}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.contents1}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.bagid1}</td>
            </tr>}
            {data.bagprice2 && <tr>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${data.bagprice2}`}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.contents2}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.bagid2}</td>
            </tr>}
            {data.bagprice3 && <tr>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${data.bagprice3}`}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.contents3}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.bagid3}</td>
            </tr>}
            {data.bagprice4 && <tr>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${data.bagprice4}`}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.contents4}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.bagid4}</td>
            </tr>}
            {data.bagprice5 && <tr>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${data.bagprice5}`}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.contents5}</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.bagid5}</td>
            </tr>}
            <tr>
              <td style={{color:'#ED2838', fontSize:12,borderColor:'#9FC2F1', borderRightWidth:0}}>Too Good To go Total</td>
              <td style={{color:'#ED2838', fontSize:12,borderColor:'#9FC2F1',borderLeftWidth:0}}></td>
              <td style={{color:'#ED2838', fontSize:12,borderColor:'#9FC2F1'}}>{`£${data.goodToGoTotal?data.goodToGoTotal:0}`}</td>
            </tr>
          </tbody>
        </>
    )
}
export default GoodToGoTable;