const Sales={
    "cake1": "0",
    "cake2": "0",
    "cake3": "0",
    "cake4": "1",
    "cake5": "0",
    "cake6": "2",
    "cake7": "1",
    "cake8": "0",
    "cake9": "0",
    "cake10": "0",
    "cake11": "1",
    "cake12": "0",
    "cake13": "2",
    "cake14": "0",
    "cake15": "0",
    "cake16": "0",
    "cake17": "2",
    "cake18": "0",
    "cake19": "0",
    "cake20": "3",
    "cake21": "1",
    "cake22": "0",
    "cake23": "4",
    "cake24": "1",
    "slice1": "11",
    "slice2": "2",
    "slice3": "7",
    "slice4": "4",
    "slice5": "3",
    "slice6": "4",
    "slice7": "8",
    "slice8": "3",
    "slice9": "5",
    "slice10": "2",
    "slice11": "0",
    "slice12": "0",
    "slice13": "0",
    "cup1": "2",
    "cup2": "1",
    "cup3": "4",
    "cup4": "1",
    "cup5": "0",
    "cup6": "0",
    "cup7": "2",
    "cup8": "1",
    "cup9": "3",
    "cup10": "0",
    "cup11": "0",
    "cup12": "6",
    "cup13": "0",
}

export default Sales;