const OnlineTable=({data})=>{
    return(
        <>
         <thead>
            <tr>
              <td scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}} >Online Sales</td>
              <td scope="col"  style={{fontSize:10, borderWidth:0,color:'#476192'}}>Orders</td>
              <td scope="col"  style={{fontSize:10, borderWidth:0,color:'#476192'}}>Amount</td>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td style={{color:'#1E4388', fontSize:12,width: '5px',borderColor:'#9FC2F1'}} className="col1">Website</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.websiteOrders}</td>
              <td style={{color:'#1E4388', fontSize:12,width: 1,borderColor:'#9FC2F1'}}>{`£${data.websiteTotal?data.websiteTotal:0}`}</td>
            </tr>
            <tr>
              <td  style={{color:'#1E4388', fontSize:12,width: '5px',borderColor:'#9FC2F1'}} className="col1">Just Eat</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.justeatOrders}</td>
              <td style={{color:'#1E4388', fontSize:12,width:1,borderColor:'#9FC2F1'}}>{`£${data.justeatTotal?data.justeatTotal:0}`}</td>
              </tr>
              <tr>
              <td style={{color:'#1E4388', fontSize:12,width: '5px',borderColor:'#9FC2F1'}} className="col1">Deliveroo</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.deliverOrders}</td>
              <td style={{color:'#1E4388', fontSize:12,width: 1,borderColor:'#9FC2F1'}}>{`£${data.deliverTotal?data.deliverTotal:0}`}</td>
              </tr>
              <tr>
              <td style={{color:'#1E4388', fontSize:12,width: '5px',borderColor:'#9FC2F1'}} className="col1">Uber Eats</td>
              <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{data.ubereatOrders}</td>
              <td style={{color:'#1E4388', fontSize:12,width: 1,borderColor:'#9FC2F1'}}>{`£${data.ubereatTotal?data.ubereatTotal:0}`}</td>
              </tr>
              <tr>
              <td style={{color:'#ED2838', fontSize:12,width: '5px',borderColor:'#9FC2F1'}} className="col1">Online Total</td>
              <td style={{color:'#ED2838', fontSize:12,borderColor:'#9FC2F1'}}>{data.onlineOrders}</td>
              <td style={{color:'#ED2838', fontSize:12,width: 1,borderColor:'#9FC2F1'}}>{`£${data.onlineTotal?data.onlineTotal:0}`}</td>
              </tr>
          </tbody>
        </>
    )
}
export default OnlineTable;