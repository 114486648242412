import React from 'react';
import { useState,useRef, useEffect } from "react";
import axios from "axios";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import TableHeader from './TableHeader';
import WalaHeader from './WalaHeader';
import { Link } from "react-router-dom";
import {IoIosArrowDropleftCircle  } from 'react-icons/io';
import WalaTableBody from './WalaTableBody';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import excel from "./assets/excel.png";
import DetailedWalaInventory from "./DetailedWalaInventory";
import MultipleWalaInventory from './MultipleWalaInventory';
import WalaSideBar from './WalaSideBar';
import SingleWalaInventorySheet from './SingleWalaInventorySheet';

const Places = [
    "Ilford",
    "Leicester",
    "Eastham",
    "Luton",
    "Central kitchen",
    "All"
  ];
const tableHeaders=[{product:'12oz cup lids' ,uom:'pcs'}, {product:'2 cup carriers', uom:'pcs'},{product:'4 cup carrier', uom:'pcs' },{product:'4oz satco', uom:'pcs'},{product:'500ml satco', uom:'pcs'},
{product:'650ml satco', uom:'pcs'},{product:'6a aluminuim containers', uom:'pcs'}, {product:'7 Up', uom:'cans'}, {product:'8oz cup & Lids', uom:'-'},{product:'8oz satco' ,uom:'pcs'},{product:'9*9*1.5 aluminuim containers', uom:'pcs'},{product:'Almond Powder' ,uom:'kg'},{product:'Alumunium foil' ,uom:'rolls'},
{product:'Amchur Powder', uom:'kg'},{product:'Brown bags(21cm*25cm*11cm)', uom:'box'},{product:'Bay Leaves', uom:'kg' },{product:'Bill rolls 80*80', uom:'pcs'},{product:'Black cardomom', uom:'kg'}, {product:'Black Pepper Powder', uom:'kg' },{product:'Broom ', uom:'pcs'},{product:'Butter Ghee', uom:'kg'},{product:'Centre feed blue rolls', uom:'pcs'},
{product:'Card rolls 57*40mm', uom:'i' },{product:'Cashew Splits' ,uom:'kg'},{product:'Caustic Soda', uom:'pcs' },{product:'Channa Dal', uom:'kg' },{product:'Channa Masala', uom:'kg'},{product:'Chunky Chat Masala', uom:'kg'},{product:'Chick Peas', uom:'kg' },{product:'Chilli Powder', uom:'kg'},{product:'Chillies Whole Red' ,uom:'kg' },{product:'Cinnamon Sticks', uom:'kg' },{product:'Cling film 300*30m', uom:'kg'},{product:'Cloves', uom:'kg'},{product:'Coconut Milk Powder', uom:'kg'},
{product:'Coffee cups 8oz', uom:'pcs'},{product:'Coke 330ml', uom:'pcs'},{product:'Cooking Salt', uom:'cans'},{product:'Cariander Powder', uom:'kg'},{product:'Corn Flour', uom:'kg'},{product:'Dalchini', uom:'kg' },{product:'Dawaat Basamathi Rice', uom:'-'},{product:'De caprio Tomato Puree', uom:'kg'},{product:'Deit coke', uom:'cans'},{product:'Dry Apricots', uom:'cans'},{product:'Dust pans', uom:'-'},{product:'Egg Noodles' ,uom:'pck'},{product:'Elachi', uom:'kg' },{product:'Elachi Powder', uom:'kg'},{product:'Elephant Atta Self Raising', uom:'kg'},{product:'Elephant medium Atta', uom:'kg'},{product:'Extra Long Rice', uom:'kg'},{product:'Fanta', uom:'kg'},{product:'Fast Dry Spray' ,uom:'cans'},
{product:'Floor Cleaner', uom:'btl'},{product:'Food Colours' ,uom:'btl'},{product:'Garbage bags' ,uom:'btl'},{product:'Ginger Garlic Paste' ,uom:'box'},{product:'Glass cleaning spray', uom:'kg'},{product:'Gloves blue vinly' ,uom:'btl'},{product:'Gram Flour', uom:'-' },{product:'Green Batani' ,uom:'kg'},{product:'Green Scruber', uom:'kg'},{product:'Hair nets' ,uom:'-'},{product:'Haldi powder', uom:'-'},{product:'Hand gloves (Heavy duty)' ,uom:'-'},{product:'Hand wash' ,uom:'-' },{product:'Hot Chilli Sauce', uom:'-'},{product:'Irani chai powder' ,uom:'kg'},{product:'Japatri', uom:'kg'},{product:'Jeera powder' ,uom:'-'},{product:'Jeera whole' ,uom:'kg'},{product:'Kaala jeera(kalonji)' ,uom:'kg'},{product:'Kashmir chilli powder', uom:'kg'},{product:'Kasuri methi', uom:'kg'},{product:'Kewara water', uom:'kg'},{product:'kitchen de greaser', uom:'btl'},{product:'Lemon juice', uom:'bts'},{product:'Lotus msg' ,uom:'btl'},
{product:'Mango pickle', uom:'kg'},{product:'Mango pulp', uom:'btl'},{product:'Masoor dal', uom:'cans'},{product:'Methi seeds' ,uom:'kg'},{product:'Mint sauce', uom:'kg'},{product:'Moong dal', uom:'btl'},{product:'Mop heads', uom:'kg'},{product:'Moratti mogga', uom:'-'},{product:'Mustard seeds', uom:'kg'},{product:'Naan bags' ,uom:'kg'},{product:'No 2 aluminuim containers' ,uom:'pcs'},{product:'Nut mug', uom:'pcs'},{product:'Papadums' ,uom:'kg'},{ product:'Peanuts red', uom:'pck'},{product:'Plastic forks' ,uom:'kg'},{product:'Plastic spoons' ,uom:'packs'},{product:'Plum tomatoes' ,uom:'packs'},{product:'Room freshner', uom:'tin'},{product:'S4 carry bags(11*17*21)' ,uom:'-'},{product:'Seaseme seeds' ,uom:'box'},{product:'Servities', uom:'kg'},{product:'Shelf life lables', uom:'-'},{product:'Smoothie cups 12oz' ,uom:'-' },{product:'Soya sauce', uom:'-'},{product:'Sparkiling Water', uom:'btl'},{product:'Sprite' ,uom:'btl'},{product:'Star ani seeds', uom:'cans'},{product:'Steel scrubber', uom:'kg'},{product:'Sugar', uom:'-'},
{product:'Tamrind (INDIAN)', uom:'kg'},{product:'Tamrind sauce' ,uom:'kg'},{product:'Tamrind wet', uom:'btl'},{product:'Tea powder', uom:'btl'},{product:'Tea towels', uom:'kg'},{product:'Thumps up' ,uom:'-'},{product:'Toilet rolls', uom:'cans'},{product:'Tomato ketchup' ,uom:'-'},{product:'Urid dal chilka' ,uom:'btl'},{product:'Veg oil' ,uom:'kg'},{product:'Vinegar white' ,uom:'lt'},{product:'Washing up liquid', uom:'lt'},{product:'Water 500ml', uom:'btl'},{product:'White pepper powder', uom:'btl'}]
const HeaderTable=['BIRYANIS', 'Boiled Keema', 'Boiled Lamb Shank', 'Marinated Chicken legs', 'Marinated Fish', 'Frypiece Chicken Biryani Curry', 'Marinated Lamb', 'Marinated Prawns', 'Mix veg Frozen', 'Paneer Cubes Marinated',
                  'BREADS', 'Naan Dough', 'Roti Dough', 'CHEMICALS', 'Anti bacterial surface cleaner', 'Caustic Soda','Degreaser Spray','Floor Cleaner','Washing Up Liquid','Glass Cleaner','Oven cleaner spray','Extra Strength Grease Lift','Toilet Cleaner','Thick Bleach',
                  'CURRIES', 'Boiled Chicken Curry Cubes','Boiled Dal Makhni','Boiled Lamb Curry Cubes','Boiled Palak Curry','Boiled Yello Dal','channa curry','Pav Bhaji Curry',
                  'DESERTS', 'Gajar ka halwa', 'Gulab jamoon', 'Qubani ka meeta', 'Rice kheer', 'DRINKS', 'Badam Milk', 'Coke 330ml', 'Mango Lassi', 'Frozen Flavoured Packs', '7Up 330ml','Fanta 330ml','Sprite 330ml','Thumbs Up','Diet Coke 330ml','Water 500ml',
                  'EQUIPMENTS', '40 lt dustbin', '80lt dustbin w lids', 'broom stick w dust pan', 'Mop bucket on Wheels', 'mop heads', 'Mop stick',
                  'ESSENTIALS', 'Cooking Oil', 'Pure Butter Ghee', 'Salt', 'Sugar', 'Sugar','Boiled Noodles', 'Chips', 'Fried Poori', 'Mint sauce', 'Raitha', 'Basmathi Rice', 'Lemon Juice', 'Toor dal', 'Almond Powdwer', 'Soy Sauce',
                  'FLOUR', 'Corn flour', 'Gram flour', 'FRESH VEGETABLES', 'Chooped Ginger small', 'Chopped Bell Peppers Cubes', 'Chopped Bell Peppers Julien', 'Chopped Cabbage Cubes', 'Chopped Cabbage Julien', 'Chopped Carrot Cubes Small', 'Chopped Carrot julien', 'Chopped Chilles daimond cut', 'Chopped Coriender', 'Chopped Curry Leaves', 'Chopped Garlic mince', 'Chopped Mint','Chopped Onion Cubes','Chopped Onion Rings', 'Chopped Onion Slices', 'Boiled Potato', 'Frozen Bhendi',
                  'GRAVIES', 'Red Gravy', 'White Gravy','Yellow Gravy','Mint Sauce( Premade)','Tomato Sauce','Biryani Gravy','Sp mint chutney',
                  'GRILL STARTERS', 'Marinated Chi Kebab', 'Marinated Chicken Wings', 'Marinated ChickenDrumsticks', 'Marinated Lamb Chops','Marinated Lamb Kebab','Marinated Tandoori Chicken','Marinated Chicken Tikka Cubes','Marinated Malai Chicken','Marinated Tikka Paneer',
                  'GROUND SPICES', 'Chaat Masala', 'Chilli powder','Coriander Powder','Haldi powder','Kashmir Chilli powder','Peri Peri Mix','White Pepper','Balck Pepper Powder','Black Pepper Coarse','Jeera Powder',
                  'MISC', 'Hair nets','Plain papad pack','Frozen Bun','Frozen Pav','Frozen samosa for Chat','Frozen Vada','Ginger Garlic Paste','Mint flavour water','Sugar Syrup','Tamrind sauce','Center Feed Blue Rolls','SoyaChumks','Yellow Colour','Red Colour','Green Colour','Tamarind concentrate','Frozen Flavoured Packs',
                  'NON-VEG STARTERS', 'Boiled Liver', 'Marinated Lollipops','Frozen allo tikka','Marinated Chicken 65','Marinated Chicken Fillet strips','Marinated Fish Cubes','Marinated Fish Strips','Marinated Haryali fish cubes','Marinated Prawns',
                  'PACKAGING', '16 oz Container', '4oz Conatiner w lid','500ml Container w lid( Eco Friendly)','650 Ml container w lid ( Eco Friendly)','9*9 Aluminium Container w lid','Large Carry Bag','Medium Carry Bag','Plastic Forks','Plastic Spoons','Small Carry bag','Tissues',
                  'SPECIALS', 'Cooked Haleem', 'Cooked Paya', 'UNIFORMS', 'aprons', 'Chef coat', 'Front staff shirts', 'T shirts long sleves','VEG STARTERS', 'Marinated Gobi', 'Semi fried Manchuria',
                  'WHOLE SPICES', 'Ajino moto', 'Bay Leaves', 'Black Cardommom', 'Black Pepper', 'Green Cardommom','Cloves','Dry Chillies', 'Dry Methi','Jeera','Kala Jeera','Kalonji','Star Anse','Mustard Seeds','Cinnamon Sticks','Fenugreek Seeds','Javentry ( mace)','Kalonjee Seeds','Whole Coriander Seeds','Nut Meg Whole','Tasting Salt','Cashew Nuts','Peanuts','Moong dal','Urid Dal', ]

const HyderabadWalaInventory = () => {
    const tableRef = useRef(null);
    const [data, setData] = useState([]);
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");
  const [duration, setDuration] = useState("");
  const [startdate, setStartdate] = useState(new Date ());
  const today = new Date();
  const [selectedstartDate, setSelectedstartDate] = useState(new Date ());
  const [selectedendDate, setSelectedendDate] = useState(new Date ());
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  const DataCount = data.length === 1? true: false
  const timeTaken = (date)=>{
    const dateTime = new Date(date);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const seconds = dateTime.getSeconds();
  const timeString = `${hours}:${minutes}:${seconds}`;
    return timeString;
  }
    
  
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  const handleDateChange = (event) => {
    setStartdate(new Date(event.target.value));
  };
  const handlestartDateChange = (event) => {
    setSelectedstartDate(new Date(event.target.value));
  };
  const handleendDateChange = (event) => {
    setSelectedendDate(new Date(event.target.value));
  };
  const changelocation = (event) => {
    setLocation(event.target.value);
  };
  var yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
  const formattedYesterdayDate = yesterday.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const changeduration = (event) => {
    setDuration(event.target.value);
   
    if (event.target.value === "custom") {
      setVisible1(false);
      setVisible2(true);
    }
    if (event.target.value === "select") {
      setVisible1(true);
      setVisible2(false);
    }
  };
  const fetchAllInventories = async () => {
    setError('')
    const dateFormat =startdate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/wala/getSingleDayinventory",
        {
          dateFormat: dateFormat,
          store: location,
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
           setData(response.data ? response.data : []);
      setError('');
      console.log('response.data****',response.data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    setError('')
    if(duration === "custom"){
      if(selectedendDate<selectedstartDate)
    {
      setError('End date should greater than start date')
      return
    }
    if(selectedendDate.getTime() === selectedstartDate.getTime())
    {
      setError('End date should greater than start date')
      return
    }
    }
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/wala/getCustomDurationInventory",
        {
          startdate: selectedstartDate.toISOString(),
          enddate: selectedendDate.toISOString(),
          store: location,
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      console.log("Data received successful:", response.data);
      setData(response.data);
      setError('');
    } catch (error) {
      console.error("Error registering user:", error.message);
      setError('Something went wrong')
    }
  };
  
  const getData = () => {
    if(location == '')
    {
      setError('Please select Location!')
      return
    }
    if(duration==='select'){
      if(startdate>today){
        setError('Selected date is future date')
        return
      }
      fetchAllInventories();
      return
    }
    
    fetchData();
    
  };

 

    
      return (
        <div >
          <WalaSideBar />
          
          <div  style={{ marginLeft: 150,marginTop:30 }}>
    <Link to="/hyderabadwala" >
  <div style={{display: 'flex'}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
            <p style={{textAlign:'left', marginTop:20,fontSize:36, color: '#244999'}} >Hyderabad Wala Inventory</p>
            <p style={{ color: 'red' }}>{error}</p>
            <div className="row">
              <div className="col-md-3" style={{marginTop:10}}>
                <select
                  className="form-control"
                  value={location}
                  onChange={changelocation}
                  style={{color: '#121314',fontSize:20, height:40}}
                >
                  <option>Location</option>
                  {Places.map((place) => (
                    <option value={place}>{place}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3" style={{marginTop:10}}>
                <select
                  className="form-control"
                  value={duration}
                  onChange={changeduration}
                  style={{color: '#121314',fontSize:20, height:40}}
                >
                  <option>Duration</option>
                  <option value="select">Select date</option>
                  <option value="custom">Custom Duration</option>
                </select>
              </div>

              <div  className="col-md-3" style={{marginTop:10 }}>
                <button onClick={getData} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Get Inventory Data</button>
              </div>
              {/* <div   className="col-md-3" style={{marginTop:10 }}>
                <DownloadTableExcel
                  filename={'Wala Inventory'}
                  sheet="Inventory"
                  currentTableRef={tableRef.current}
                >

                  <button style={{fontSize:20,height:40, color:'#FFFFFF',backgroundColor:'#048E5B',borderWidth:0,borderRadius:5}} ><img src={excel} alt="Logo" style={{width:18, height:20}}/> Export excel </button>

                </DownloadTableExcel>
              </div> */}
            </div>
            {visible1 && <div className="col-md-3" style={{ marginTop: 50,borderWidth:2,alignItems:'center', justifyContent:'space-evenly' }}>
            <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5}}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
              <label style={{textAlign:'center',color:'#6E6E6E'}}>Select Date :</label>
              </div>
              <input
                type="date"
                value={startdate.toISOString().split('T')[0]}
                onChange={handleDateChange}
                style={{ border: 'none' , height:30,marginTop:5,marginBottom:5,color:'#121314', fontSize:20}} />
                
                </div>
            </div>}
            {visible2 && <div className="row" style={{ marginTop: 50 , alignItems:'center', justifyContent:'space-evenly'}}>
              <div className="col-md-3" >
              <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5, marginTop:10}}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
                <label style={{textAlign:'center',color:'#6E6E6E'}}>Start Date :</label>
                </div>
                <input
                  type="date"
                  value={selectedstartDate.toISOString().split('T')[0]}
                  onChange={handlestartDateChange}
                  style={{ border: 'none' , height:30,color:'#121314', fontSize:20,marginTop:5 }} />
                  </div>
              </div>
              <div className="col-md-3" >
              <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5, marginTop:10}}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
                <label style={{textAlign:'center',color:'#6E6E6E'}}>End Date :</label>
                </div>
                <input
                  type="date"
                  value={selectedendDate.toISOString().split('T')[0]}
                  onChange={handleendDateChange}
                  style={{ border: 'none' , height:30,color:'#121314', fontSize:20,marginTop:5,}}/>
                  </div>
              </div>
            </div>}
            {/* <div style={{ marginTop: 30 }}>
              <table class="table-bordered " ref={tableRef} >
                <WalaHeader headers={HeaderTable} />
                <WalaTableBody contents={data} />
                {NewHeaders.map((header)=>(
                  <tr key={header.id}>
                  <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:200, height:20}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                ))}
                  
              </table>
            </div> */}
            {/* {DataCount && <div style={{marginTop:30}}>
            <DetailedWalaInventory data={data}/>
            </div>}
           { !DataCount && <div style={{marginTop:30}}>
            <MultipleWalaInventory data={data}/>
            </div>} */}
            <div style={{marginTop:30}}>
            <SingleWalaInventorySheet data={data}/>
            </div>
          </div>

          </div>
      );
};

export default HyderabadWalaInventory;
