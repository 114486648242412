import React, { useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import dashboard1 from "./assets/dashboard1.png";
import dashboard2 from "./assets/dashboard2.png";
import dashboard3 from "./assets/dashboard3.png";
import dashboard4 from "./assets/dashboard4.png";

const Layout = () => {
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  return (
    <div className="App-header" style={{backgroundColor:'#244999'}}>
      <div className="row" style={{margin:10}}>
      
          <Link to="/hyderabadwala" style={{ borderRadius:5, height:'200px', width:200, color: 'white', border: '2px solid white',  marginRight:30, backgroundColor:'white', alignItems:'center', justifyContent:'center',marginTop:10 }}>
        <div >
        <img src={dashboard3} alt="Description of the image" style={{width:180, height:180}}/>
       
        </div>
          </Link>
        
          <Link to="/cakes&bakes" style={{ borderRadius:5,height:'200px', width:200, color: 'white', border: '2px solid white',   backgroundColor:'white', alignItems:'center', justifyContent:'center', marginTop:10 }}>
        <div style={{marginTop:50}}>
        <img src={dashboard4} alt="Description of the image" style={{width:100, height:80}}/>
       
        </div>
          </Link>
          </div>
 </div>
  )
};

export default Layout;