import React from 'react';

const TableHeader = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <th scope="row" style={{color:'#393939', fontSize:14}}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;