import "./App.css";
import { useState,useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route,Link } from "react-router-dom";
import axios from "axios";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import MainScreen from "./MainScreen";
import Layout from "./Layout";
import HyderabadWalaInventory from "./HyderabadWalaInventory";
import CakesInventory from "./CakesInventory";
import LoginPage from "./LoginPage";
import CakesVerificationScreen from "./CakesVerificationScreen";
import WalaVerificationScreen from "./WalaVerificationScreen";
import CakesSummaryScreen from "./CakesSummaryScreen";
import WalaLayout from "./WalaLayout";
import CakesLayout from "./CakesLayout";
import InventoryOrder from "./InventoryOrder";
import RequirementsList from "./RequirementsList";
import SaleReport from "./SaleReport";
import CakesCashSaleReportjs from "./CashSaleReport";



function App() {
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  const Logout = ()=>{
    localStorage.removeItem('username');
    localStorage.removeItem('isLoggedIn');
 
  }
  useEffect(() => {
    // Start the logout timer when the user logs in
    const logoutTimer = setTimeout(() => {
      Logout();
    }, 6000000); // Adjust the time as needed (e.g., 1 minute)

    // Clear the timer when the component unmounts or when the user logs out manually
    return () => clearTimeout(logoutTimer);
  }, [isAuthenticated]);
  return (
    <Router>
    <div className="App">
      
      <Routes>
      <Route
          exact
          path="/"
          element={<LoginPage/>}
        ></Route>
         <Route
          exact
          path="/home"
          element={<Layout/>}
        ></Route>
        <Route
          exact
          path="/hyderabadwala"
          element={<WalaLayout/>}
        ></Route>
         <Route
          exact
          path="/cakes&bakes"
          element={<CakesLayout/>}
        ></Route>
        <Route
          exact
          path="/hyderabadwala/summary"
          element={<MainScreen/>}
        ></Route>
             <Route
          exact
          path="/cakes&bakes/summary"
          element={<CakesSummaryScreen/>}
        ></Route>
        <Route
          exact
          path="/cakes&bakes/requirementsList"
          element={<RequirementsList/>}
        ></Route>
        <Route
          exact
          path="/cakes&bakes/verify"
          element={<CakesVerificationScreen />}
        ></Route>
         <Route
          exact
          path="/hyderabadwala/verify"
          element={<WalaVerificationScreen />}
        ></Route>
        <Route
          exact
          path="/hyderabadwala/inventory"
          element={<HyderabadWalaInventory />}
        ></Route>
         <Route
          exact
          path="/cakes&bakes/inventory"
          element={<CakesInventory />}
        ></Route>
        <Route
          exact
          path="/cakes&bakes/inventoryOrder"
          element={<InventoryOrder />}
        ></Route>
         <Route
          exact
          path="/cakes&bakes/sales"
          element={<SaleReport />}
        ></Route>
        <Route
          exact
          path="/cakes&bakes/saleReport"
          element={<CakesCashSaleReportjs />}
        ></Route>
      </Routes>
    </div>
  </Router>
  );
}



export default App;
