const Tablerow =({item, qty, total})=>{
    return(
        <tr key={item}>
      <td scope="row" style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1'}} >{item}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{qty}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{`£${total?total:0}`}</td>
      
        </tr>
    )
}
export default Tablerow;