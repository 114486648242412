import React from 'react';
import { useState,useRef, useEffect } from "react";
import axios from "axios";
import SaleReportRow from './SaleReportRow';

const SaleReportTable = ({inventoryData, inventoryData2, sales, stock}) => {
  const [location, setLocation] = useState("");
  const [cake1, setCake1] = useState('')
  const [cake2, setCake2] = useState('');
    const [cake3, setCake3] = useState('');
    const [cake4, setCake4] = useState('');
    const [cake5, setCake5] = useState('');
    const [cake6, setCake6] = useState('');
    const [cake7, setCake7] = useState('');
    const [cake8, setCake8] = useState('');
    const [cake9, setCake9] = useState('');
    const [cake10, setCake10] = useState('');
    const [cake11, setCake11] = useState('');
    const [cake12, setCake12] = useState('');
    const [cake13, setCake13] = useState('');
    const [cake14, setCake14] = useState('');
    const [cake15, setCake15] = useState('');
    const [cake16, setCake16] = useState('');
    const [cake17, setCake17] = useState('');
    const [cake18, setCake18] = useState('');
    const [cake19, setCake19] = useState('');
    const [cake20, setCake20] = useState('');
    const [cake21, setCake21] = useState('');
    const [cake22, setCake22] = useState('');
    const [cake23, setCake23] = useState('');
    const [cake24, setCake24] = useState('');
    const [cup1, setCup1] = useState();
    const [cup2, setCup2] = useState();
    const [cup3, setCup3] = useState();
    const [cup4, setCup4] = useState();
    const [cup5, setCup5] = useState();
    const [cup6, setCup6] = useState();
    const [cup7, setCup7] = useState();
    const [cup8, setCup8] = useState();
    const [cup9, setCup9] = useState();
    const [cup10, setCup10] = useState();
    const [cup11, setCup11] = useState();
    const [cup12, setCup12] = useState();
    const [cup13, setCup13] = useState();
    const [slice1, setSlice1] = useState();
    const [slice2, setSlice2] = useState();
    const [slice3, setSlice3] = useState();
    const [slice4, setSlice4] = useState();
    const [slice5, setSlice5] = useState();
    const [slice6, setSlice6] = useState();
    const [slice7, setSlice7] = useState();
    const [slice8, setSlice8] = useState();
    const [slice9, setSlice9] = useState();
    const [slice10, setSlice10] = useState();
    const [slice11, setSlice11] = useState();
    const [slice12, setSlice12] = useState();
    const [slice13, setSlice13] = useState();
  
      return (
    <tbody>
    <SaleReportRow product='Mini choco vanilla' id={12}  inventory2={inventoryData2[0].cake1} sales={sales.cake1} stock={stock} inventory={inventoryData[0].cake1} diff={cake1} setDiff={setCake1} />
    <SaleReportRow product='Mini fruit vanilla' id={12} inventory2={inventoryData2[0].cake2} sales={sales.cake2} stock={stock} inventory={inventoryData[0].cake2} diff={cake2} setDiff={setCake2} />
    <SaleReportRow product='Mini ganache cake' id={12} inventory2={inventoryData2[0].cake3} sales={sales.cake3} stock={stock} inventory={inventoryData[0].cake3} diff={cake3} setDiff={setCake3} />
    <SaleReportRow product='Mini Oreo cake' id={12} inventory2={inventoryData2[0].cake4} sales={sales.cake4} stock={stock} inventory={inventoryData[0].cake4} diff={cake4} setDiff={setCake4} />
    <SaleReportRow product='Mini raffaello cake' id={12} inventory2={inventoryData2[0].cake5} sales={sales.cake5} stock={stock} inventory={inventoryData[0].cake5} diff={cake5} setDiff={setCake5} />
    <SaleReportRow product='Mini Biscoff cake' id={12} inventory2={inventoryData2[0].cake6} sales={sales.cake6} stock={stock} inventory={inventoryData[0].cake6} diff={cake6} setDiff={setCake6} />
    <SaleReportRow product='Pink vanilla cake' id={135} inventory2={inventoryData2[0].cake7} sales={sales.cake7} stock={stock} inventory={inventoryData[0].cake7} diff={cake7} setDiff={setCake7} />
    <SaleReportRow product='Blue vanilla cake' id={136} inventory2={inventoryData2[0].cake8} sales={sales.cake8} stock={stock} inventory={inventoryData[0].cake8} diff={cake8} setDiff={setCake8} />
    <SaleReportRow product='Black forest cake' id={146} inventory2={inventoryData2[0].cake9} sales={sales.cake9} stock={stock} inventory={inventoryData[0].cake9} diff={cake9} setDiff={setCake9} />
    <SaleReportRow product='Chocolate heaven cake' id={12} inventory2={inventoryData2[0].cake10} sales={sales.cake10} stock={stock} inventory={inventoryData[0].cake10} diff={cake10} setDiff={setCake10} />
    <SaleReportRow product='Choco vanilla cake' id={12} inventory2={inventoryData2[0].cake11} sales={sales.cake11} stock={stock} inventory={inventoryData[0].cake11} diff={cake11} setDiff={setCake11} />
    <SaleReportRow product='Ferrero Rocher cake' id={145} inventory2={inventoryData2[0].cake12} sales={sales.cake12} stock={stock} inventory={inventoryData[0].cake12} diff={cake12} setDiff={setCake12} />
    <SaleReportRow product='Mango cake' id={141} inventory2={inventoryData2[0].cake13} sales={sales.cake13} stock={stock} inventory={inventoryData[0].cake13} diff={cake13} setDiff={setCake13} />
    <SaleReportRow product='Nutella cake' id={143} inventory2={inventoryData2[0].cake14} sales={sales.cake14} stock={stock} inventory={inventoryData[0].cake14} diff={cake14} setDiff={setCake14} />
    <SaleReportRow product='Pistachio cake' id={12} inventory2={inventoryData2[0].cake15} sales={sales.cake15} stock={stock} inventory={inventoryData[0].cake15} diff={cake15} setDiff={setCake15} />
    <SaleReportRow product='Fruit paradise cake' id={12} inventory2={inventoryData2[0].cake16} sales={sales.cake16} stock={stock} inventory={inventoryData[0].cake16} diff={cake16} setDiff={setCake16} />
    <SaleReportRow product='Red velvet cake' id={12} inventory2={inventoryData2[0].cake17} sales={sales.cake17} stock={stock} inventory={inventoryData[0].cake17} diff={cake17} setDiff={setCake17} />
    <SaleReportRow product='Strawberry cake' id={12} inventory2={inventoryData2[0].cake18} sales={sales.cake18} stock={stock} inventory={inventoryData[0].cake18} diff={cake18} setDiff={setCake18} />
    <SaleReportRow product='White forest cake' id={12} inventory2={inventoryData2[0].cake19} sales={sales.cake19} stock={stock} inventory={inventoryData[0].cake19} diff={cake19} setDiff={setCake19} />
    <SaleReportRow product='Lotus biscoff cake' id={12} inventory2={inventoryData2[0].cake20} sales={sales.cake20} stock={stock} inventory={inventoryData[0].cake20} diff={cake20} setDiff={setCake20} />
    <SaleReportRow product='Oreo cake' id={12} inventory2={inventoryData2[0].cake21} sales={sales.cake21} stock={stock} inventory={inventoryData[0].cake21} diff={cake21} setDiff={setCake21} />
    <SaleReportRow product='Only berries cake' id={12} inventory2={inventoryData2[0].cake22} sales={sales.cake22} stock={stock} inventory={inventoryData[0].cake22} diff={cake22} setDiff={setCake22} />
    <SaleReportRow product='Fruitful cake' id={12} inventory2={inventoryData2[0].cake23} sales={sales.cake23} stock={stock} inventory={inventoryData[0].cake23} diff={cake23} setDiff={setCake23} />
    <SaleReportRow product='Chocolate overload cake' id={12} inventory2={inventoryData2[0].cake24} sales={sales.cake24} stock={stock} inventory={inventoryData[0].cake24} diff={cake24} setDiff={setCake24} />
    <SaleReportRow product='Mango cupcakes' id={12} inventory2={inventoryData2[0].cup1} sales={sales.cup1} stock={stock} inventory={inventoryData[0].cup1} diff={cup1} setDiff={setCup1} />
    <SaleReportRow product='Lotus Biscoff cupcakes' id={156} inventory2={inventoryData2[0].cup2} sales={sales.cup2} stock={stock} inventory={inventoryData[0].cup2} diff={cup2} setDiff={setCup2} />
    <SaleReportRow product='Rainbow cupcakes' id={12} inventory2={inventoryData2[0].cup3} sales={sales.cup3} stock={stock} inventory={inventoryData[0].cup3} diff={cup3} setDiff={setCup3} />
    <SaleReportRow product='Blondie cupcakes' id={12} inventory2={inventoryData2[0].cup4} sales={sales.cup4} stock={stock} inventory={inventoryData[0].cup4} diff={cup4} setDiff={setCup4} />
    <SaleReportRow product='Pink vanilla cupcakes' id={12} inventory2={inventoryData2[0].cup5} sales={sales.cup5} stock={stock} inventory={inventoryData[0].cup5} diff={cup5} setDiff={setCup5} />
    <SaleReportRow product='Blue vanilla cupcakes' id={12} inventory2={inventoryData2[0].cup6} sales={sales.cup6} stock={stock} inventory={inventoryData[0].cup6} diff={cup6} setDiff={setCup6} />
    <SaleReportRow product='Pistachio cupcakes' id={12} inventory2={inventoryData2[0].cup7} sales={sales.cup7} stock={stock} inventory={inventoryData[0].cup7} diff={cup7} setDiff={setCup7} />
    <SaleReportRow product='Oreo cupcakes' id={154} inventory2={inventoryData2[0].cup8} sales={sales.cup8} stock={stock} inventory={inventoryData[0].cup8} diff={cup8} setDiff={setCup8} />
    <SaleReportRow product='Ferrero cupcakes' id={153} inventory2={inventoryData2[0].cup9} sales={sales.cup9} stock={stock} inventory={inventoryData[0].cup9} diff={cup9} setDiff={setCup9} />
    <SaleReportRow product='Black forest cupcakes' id={12} inventory2={inventoryData2[0].cup10} sales={sales.cup10} stock={stock} inventory={inventoryData[0].cup10} diff={cup10} setDiff={setCup10} />
    <SaleReportRow product='Brownie cupcakes' id={12} inventory2={inventoryData2[0].cup11} sales={sales.cup11} stock={stock} inventory={inventoryData[0].cup11} diff={cup11} setDiff={setCup11} />
    <SaleReportRow product='Red velvet cupcakes' id={163} inventory2={inventoryData2[0].cup12} sales={sales.cup12} stock={stock} inventory={inventoryData[0].cup12} diff={cup12} setDiff={setCup12} />
    <SaleReportRow product='Carrot cupcakes' id={12} inventory2={inventoryData2[0].cup13} sales={sales.cup13} stock={stock} inventory={inventoryData[0].cup13} diff={cup13} setDiff={setCup13} />
    <SaleReportRow product='Mango slices' id={165} inventory2={inventoryData2[0].slice1} sales={sales.slice1} stock={stock} inventory={inventoryData[0].slice1} diff={slice1} setDiff={setSlice1} />
    <SaleReportRow product='Biscoff slices' id={174} inventory2={inventoryData2[0].slice2} sales={sales.slice2} stock={stock} inventory={inventoryData[0].slice2} diff={slice2} setDiff={setSlice2} />
    <SaleReportRow product='Strawberry slices' id={166} inventory2={inventoryData2[0].slice3} sales={sales.slice3} stock={stock} inventory={inventoryData[0].slice3} diff={slice3} setDiff={setSlice3} />
    <SaleReportRow product='Pistachio slices' id={167} inventory2={inventoryData2[0].slice4} sales={sales.slice4} stock={stock} inventory={inventoryData[0].slice4} diff={slice4} setDiff={setSlice4} />
    <SaleReportRow product='Oreo slices' id={173} inventory2={inventoryData2[0].slice5} sales={sales.slice5} stock={stock} inventory={inventoryData[0].slice5} diff={slice5} setDiff={setSlice5} />
    <SaleReportRow product='Black forest slices' id={169} inventory2={inventoryData2[0].slice6} sales={sales.slice6} stock={stock} inventory={inventoryData[0].slice6} diff={slice6} setDiff={setSlice6} />
    <SaleReportRow product='Ferrero slices' id={170} inventory2={inventoryData2[0].slice7} sales={sales.slice7} stock={stock} inventory={inventoryData[0].slice7} diff={slice7} setDiff={setSlice7} />
    <SaleReportRow product='Truffle slices' id={171} inventory2={inventoryData2[0].slice8} sales={sales.slice8} stock={stock} inventory={inventoryData[0].slice8} diff={slice8} setDiff={setSlice8} />
    <SaleReportRow product='Red velvet slices' id={180} inventory2={inventoryData2[0].slice9} sales={sales.slice9} stock={stock} inventory={inventoryData[0].slice9} diff={slice9} setDiff={setSlice9} />
    <SaleReportRow product='Carrot slices' id={12} inventory2={inventoryData2[0].slice10} sales={sales.slice10} stock={stock} inventory={inventoryData[0].slice10} diff={slice10} setDiff={setSlice10} />
    <SaleReportRow product='Lemon & blueberry slices' id={12} inventory2={inventoryData2[0].slice11} sales={sales.slice11} stock={stock} inventory={inventoryData[0].slice11} diff={slice11} setDiff={setSlice11} /> 
    <SaleReportRow product='Truffle & orange slices' id={12} inventory2={inventoryData2[0].slice12} sales={sales.slice12} stock={stock} inventory={inventoryData[0].slice12} diff={slice12} setDiff={setSlice12} />
    <SaleReportRow product='Rainbow slices' id={172} inventory2={inventoryData2[0].slice13} sales={sales.slice13} stock={stock} inventory={inventoryData[0].slice13} diff={slice13} setDiff={setSlice13} />
    
   </tbody>
       
      );
};

export default SaleReportTable;
