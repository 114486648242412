import React from 'react';
import './Sidebar.css'; // Import CSS file for styling
import { Outlet, Link } from "react-router-dom";
import cakeLogo from "./assets/cake.png";
import verifyLogo from "./assets/verify.png";
import homeLogo from "./assets/home.png";
import summaryLogo from "./assets/summary.png";
import walaLogo from "./assets/wala.png";
import logoutLogo from "./assets/logout.png";
import { useNavigate } from 'react-router-dom';

const CustomSideBar =()=> {
  const history = useNavigate();
  const Logout = ()=>{
    localStorage.removeItem('username');
    localStorage.removeItem('isLoggedIn');
    history('/'); 
  }
    return (
      <div className="sidebar">
        {/* Sidebar content */}
        <ul>
        <li><Link to="/home" ><img src={homeLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/cakes&bakes/summary" ><img src={summaryLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/cakes&bakes/verify" ><img src={verifyLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/cakes&bakes/inventory" ><img src={cakeLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/cakes&bakes/saleReport" ><p style={{color:'#FFFFFF'}}>Sale Report</p></Link></li>
         
          <li className="fixed-item" style={{backgroundColor:'#048E5B', position:'absolute'}}><div onClick={()=>Logout()}>
            <img src={logoutLogo} alt="Description of the image" style={{width:30, height:30}}/>
            <p style={{color:'#FFFFFF'}}>Logout</p>
            </div>
            </li>
        </ul>
        
      </div>
    );
  }


export default CustomSideBar;
