import React,{useState,useEffect} from 'react';
import TableBody from './VerificationSection1Table';
import Tablerow from './TableRow';
import OnlineTable from './OnlineTable';
import GoodToGoTable from './GoodToGoTable';
import VerificationRow from './VerificationRow';
import TableSection from './TableSection';
import CheckBoxInput from './CheckBoxInput';
import axios from 'axios';
import TwocoloumntableRow from './TwocoloumntableRow';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CashTable from './CashTable';
import PurchaseTable from './PurchaseTable';
import WastageTable from './WastageTable';
import EnlargableImage from './EnlargeImage';

const VerificationSection1 = ({ content,setError }) => {
    const [data, setData] = useState(content)
    const [ispettycash, setIspettycash] = useState(data.ispettycash);
    const [iscashsale, setIscashsale] = useState(data.iscashsale);
    const [iscard, setIscard] = useState(data.iscard);
    const [isstoresale, setIsstoresale] = useState(data.isstoresale);
    const [issubtotal, setIssubtotal] = useState(data.issubtotal);
    const [iscashmanag, setIscashmanag] = useState(data.iscashmanag);
    const [istodayfloat, setIstodayfloat] = useState(data.istodayfloat);
    const [istotalSales, setIstotalSales] = useState(data.istotalSales);
    const [isChecked, setIsChecked] = useState(data.verified);
    const [isSubmit, setSubmit] = useState(data.isSubmitted);
    const [comment, setComment] = useState(data.comment);
    const sumTotal = data?.wastages?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.totalprice);
    }, 0);
    const showToast = () => {
      toast.success('Submitted Successfully', {
        position: 'top-right',
        autoClose: 2000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    const submitdata=async()=>{
      const submittedFlag = ispettycash && iscashsale && isChecked && iscard&&iscashmanag
      && isstoresale && issubtotal && istodayfloat &&istotalSales
      const payload = {
        id:data._id,
        ispettycash:ispettycash,
        iscashsale:iscashsale,
        iscard:iscard,
        isstoresale:isstoresale,
        iscashmanag:iscashmanag,
        issubtotal:issubtotal,
        istodayfloat:istodayfloat,
        istotalSales:istotalSales,
        verified:isChecked,
        isSubmitted:submittedFlag,
        comment:comment,
       
      }
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      try {
        const response = await axios.post('http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/daysheetVerification',payload,
        {
          headers:headers
        }
        );
        console.log('submittedFlag***', submittedFlag);
        setSubmit(submittedFlag);
        setData(response.data);
        showToast();
        setError('')
      } catch (error) {
        console.error('Error registering user:', error.message);
        setError('Something went wrong! Please try again');
      }
    }
    useEffect(()=>{
      setData(content)
    },[content])
    useEffect(() => {
      setIspettycash(data.ispettycash);
      setIscashsale(data.iscashsale);
      setIscard(data.iscard);
      setIsstoresale(data.isstoresale);
      setIscashmanag(data.iscashmanag);
      setIssubtotal(data.issubtotal);
      setIstodayfloat(data.istodayfloat);
      setIstotalSales(data.istotalSales);
      setIsChecked(data.verified);
      setSubmit(data.isSubmitted);
      setComment(data.comment);
     
    }, [data]);
    const rowStyle = {
     
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      
    };
  
  return (
    <div >
       <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
       <div className='row ' style={{marginTop:20, marginBottom:20, borderBottom: '1px solid #D2D2D2'}}>
       <div className="col-md-3 " >
        <div  style={{alignItems:'center',lineHeight:0.5}}>
          <p style={{ fontSize:18, color:'#121314', textAlign:'left'}}>Store </p>
          <p style={{  fontSize:24,textAlign:'left', color:'#244999'}}> {data.shop}</p>
          </div>
          </div>
            <div className="col-md-3">
          <div  style={{alignItems:'center',lineHeight:0.5}}>
          <p style={{ fontSize:18, color:'#121314', textAlign:'left'}}>Location </p>
          <p style={{ fontSize:24,textAlign:'left', color:'#244999'}}> {data.store}</p>
          </div>
          </div>
          <div className="col-md-3">
          <div  style={{alignItems:'center',lineHeight:0.5}}>
          <p style={{ fontSize:18,textAlign:'left'}}>Date</p>
          <p style={{fontSize:24,textAlign:'left', color:'#244999'}}> {data.dateFormat}</p>
          </div>
          </div>
        </div>
    <div className='row'>
      <div className="col-md-3" style={{border: '1px solid #DCDCDC' }}>
     <CashTable data={data}/> 
     </div>
     <div className="col-md-3" >
      <div style={{border: '1px solid #DCDCDC' }}>
     <div style={{padding:5, paddingBottom:0, paddingTop:0}} >
        <p style={{textAlign:'left', marginTop:10, color:'#048E5B', fontSize:25}}>Online Section</p>
        <table class="table " >
        <OnlineTable data ={data}/>
        </table>
        </div>
        </div>
        <div style={{border: '1px solid #DCDCDC', marginTop:10 }}>
     <div style={{padding:5, paddingBottom:0, paddingTop:0}} >
        <p style={{textAlign:'left', marginTop:10, color:'#048E5B', fontSize:25}}>Purchases</p>
        <table class="table " >
        <PurchaseTable data={data.purchases?data.purchases:[]}/>
        </table>
        </div>
        </div>
     </div>
     <div className="col-md-3" >
      <div style={{border: '1px solid #DCDCDC' }}>
     <div style={{padding:5, paddingBottom:0, paddingTop:0}} >
        <p style={{textAlign:'left', marginTop:10, color:'#048E5B', fontSize:25}}>Too Good To Go</p>
        <table class="table " >
        <GoodToGoTable data={data}/>
        </table>
        </div>
        </div>
        <div style={{border: '1px solid #DCDCDC', marginTop:10 }}>
     <div style={{padding:5, paddingBottom:0, paddingTop:0}} >
        <p style={{textAlign:'left', marginTop:10, color:'#048E5B', fontSize:25}}>Wastages</p>
        <table class="table " >
        <WastageTable data={data.wastages?data.wastages:[]}/>
        </table>
        </div>
        </div>
     </div>
   <div className="col-md-3" style={{border: '1px solid #DCDCDC',}}>
    <div >
      <p style={{textAlign:'left', marginTop:10, color:'#048E5B', fontSize:30}}>Total</p>
   <table class="table " >
    <thead>
    <td scope="col" style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Name</td>
          <td scope="col" style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Amount</td>
          <td scope="col" style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Verified</td>
          
    </thead>
    <tbody>
    <TwocoloumntableRow item='Total Cash in Till (A)' amount={data.tillcash} />
    <TwocoloumntableRow item='Yesterday Float (B)' amount={data.yesfloat} />
    <VerificationRow item='Cash Sale (A-B)' amount={data.cashsale} ischeck={iscashsale} setIscheck={setIscashsale}  isSubmit={isSubmit}/>
    <VerificationRow item='Card (D)' amount={data.card} ischeck={iscard} setIscheck={setIscard}  isSubmit={isSubmit}/>
    <VerificationRow item='Store Sale (Cash+D)' amount={data.storesales} ischeck={isstoresale} setIscheck={setIsstoresale} isSubmit={isSubmit}/>
    <VerificationRow item='Petty Cash (E)' amount={data.pettycash} ischeck={ispettycash} setIscheck={setIspettycash}  isSubmit={isSubmit}/>
    <VerificationRow item='Subtotal (Cash-E)' amount={data.subtotal} ischeck={issubtotal} setIscheck={setIssubtotal} isSubmit={isSubmit}/>
    <VerificationRow item='Cash to Management(F)' amount={data.cashmanag} ischeck={iscashmanag} setIscheck={setIscashmanag} isSubmit={isSubmit}/>
    <VerificationRow item='Today Float(B+Subtotal-F)' amount={data.todayfloat} ischeck={istodayfloat} setIscheck={setIstodayfloat} isSubmit={isSubmit}/>
    <TwocoloumntableRow item='Online Total' amount={data.onlineTotal} />
    <VerificationRow item='Total Sales(Store + Online)' amount={data.totalSales} ischeck={istotalSales} setIscheck={setIstotalSales}  isSubmit={isSubmit}/>
    <TwocoloumntableRow item='Too Good To Go' amount={data.goodToGoTotal} />
    <tr >
      <td scope="row" style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1'}}>Wastage</td>
      <td style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1'}}>{`£${sumTotal?sumTotal?.toFixed(2):0}`}</td>
      <td style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1'}}></td>
     
        </tr>
    
   </tbody>
   </table>
   </div>
   </div>
  
    </div>
    <div className='row' style={{marginTop:10}}>
    <p style={{fontSize:20,  color:'#048E5B'}}>Other Info:   </p>
      <p style={{fontSize:20, color:'#1E4388', marginLeft:5}}>{content.otherinfo}</p>
    </div>
    <div className='row' style={{marginTop:20}}>
      <div className="col-md-4">
      <p style={{textAlign:'left', color:'#048E5B', fontSize:20}}>Envelope Image</p>
        {data.image && <EnlargableImage src={data.image} alt="Description of the image" />}
      </div>
      <div className="col-md-4">
      <p style={{textAlign:'left', color:'#048E5B', fontSize:20}}>Purchases Receipt Image</p>
        {data.purchaseimage && <EnlargableImage src={data.purchaseimage} alt="Description" />}
      </div>
   <div className="col-md-4" >
    <div className='row'>
   <label htmlFor="myInput" style={{textAlign:'left', color:'#048E5B', fontSize:20}}>Comments</label>
      <textarea
        type="text"
        id="myInput"
        style={{ width: '90%', height: '100px', padding: '10px' }}
        value={comment}
        disabled={isSubmit}
        onChange={(event)=> setComment(event.target.value)}
      />
      </div>
    </div>
    </div>
   <div className='row' style={{marginTop:50, alignItems:'center',  justifyContent:'center'}}>
   
   <label htmlFor="myInput" style={{color:'#244999', fontSize:20}}>Verification </label>
      <input
        type="checkbox"
        checked={isChecked}
        disabled={isSubmit}
        style={{marginLeft:'10px', width:25, height:25}}
        onChange={()=>setIsChecked(!isChecked)}
      />
   
    <button onClick={()=>submitdata()  } disabled={isSubmit || Object.keys(data).length === 0} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#048E5B', borderBlockColor:'#048E5B', marginLeft:50, width:200}}>Submit Now</button>
   
    </div>
   
   </div>
  );
};

export default VerificationSection1;