const WastageTable=({data})=>{
    return(
        <>
        <thead>
          <tr>
            <th scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Material Name</th>
            <th scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>QTY</th>
            <th scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Amount</th>
            <th scope="col"  style={{fontSize:10,  borderWidth:0, color:'#476192'}}>Reason</th>
          </tr>
       </thead>
       <tbody>
      
        {data.map((wastage) => (
         <tr>
          <td style={{color:'#1E4388', fontSize:10, borderColor:'#9FC2F1'}}>{wastage.item}</td>
          <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{wastage.quantity}</td>
          <td style={{color:'#1E4388', fontSize:12,borderColor:'#9FC2F1'}}>{`£${wastage.totalprice}`}</td>
          <td style={{color:'#1E4388', fontSize:10,borderColor:'#9FC2F1'}}>{wastage.reason}</td>
          </tr>
        ))}
     
    </tbody>
        </>
    )
}
export default WastageTable;