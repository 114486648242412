import { useState,useRef, useEffect } from "react";

const PurchaseTablerow =({product, qty, inventory, diff, setDiff})=>{
  const dup = parseFloat(qty) - parseFloat(inventory)
  const handleInputChange = (e, setDiff) => {
    setDiff(e.target.value);
  };
  useEffect(()=>{
    setDiff(dup)
  },[dup])
  
    return(
        <tr key={product}>
      <td scope="row" style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1'}} >{product}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{qty}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}>{inventory}</td>
      <td style={{color:'#1E4388', fontSize:14,borderColor:'#9FC2F1'}}> <input
                  type="text"
                  value={diff}
                  style={{border:'none'}}
                  onChange={(e) => handleInputChange(e, setDiff)}
                /></td>

      
        </tr>
    )
}
export default PurchaseTablerow;