import React from 'react';
import './Sidebar.css'; // Import CSS file for styling
import { Outlet, Link } from "react-router-dom";
import cakeLogo from "./assets/cake.png";
import verifyLogo from "./assets/verify.png";
import homeLogo from "./assets/home.png";
import summaryLogo from "./assets/summary.png";
import walaLogo from "./assets/wala.png";
import logoutLogo from "./assets/logout.png";
import { useNavigate } from 'react-router-dom';

const WalaSideBar =()=> {
  const history = useNavigate();
  const Logout = ()=>{
    localStorage.removeItem('username');
    localStorage.removeItem('isLoggedIn');
    history('/'); 
  }
    return (
      <div className="sidebar">
        {/* Sidebar content */}
        <ul>
        <li><Link to="/home" ><img src={homeLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/hyderabadwala/summary" ><img src={summaryLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/hyderabadwala/verify" ><img src={verifyLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li><Link to="/hyderabadwala/inventory" ><img src={walaLogo} alt="Description of the image" style={{width:35, height:35}}/></Link></li>
          <li className="fixed-item" style={{backgroundColor:'#048E5B'}}><div onClick={()=>Logout()}>
            <img src={logoutLogo} alt="Description of the image" style={{width:30, height:30}}/>
            <p style={{color:'#FFFFFF'}}>Logout</p>
            </div>
            </li>
        </ul>
        
      </div>
    );
  }


export default WalaSideBar;
