import React, { useState } from 'react';
import axios from 'axios';

const CheckBoxInput =({isChecked, setIsChecked,isSubmit})=>{
  
    return(
        <div style={{alignItems:'center', justifyItems:'center'}}>
      
          <input
            type="checkbox"
            disabled={isSubmit}
            checked={isChecked}
            onChange={()=>setIsChecked(!isChecked)}
            style={{backgroundColor: isChecked ? 'green' : 'black'}}
          />
          
       
      </div>
    )
}

export default CheckBoxInput;