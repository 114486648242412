import React,{useState,useEffect,useRef, } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import excel from "./assets/excel.png";


                  
const CleaningHeaders = [
  {header:'Flash Degreaser-6X750ml', id:'cleaning1',num:1},{header:'Flash Disinfectant -6X750ml', id:'cleaning2',num:2},{header:'Thick Bleach-4X5L', id:'cleaning3',num:3},{header:'Washing Up liquid-6X900ml', id:'cleaning4',num:4},{header:'Blue Rolls-6X6', id:'cleaning5',num:5},{header:'Caustic Soda-6X1KG', id:'cleaning6',num:6},{header:'Hand Wash-6X500Ml', id:'cleaning7',num:7,},{num:8,header:'Sanitising Tablets-1X230Tab', id:'cleaning8'},{num:9,header:'Green Scouring pads-1X10', id:'cleaning9'},{num:10,header:'Checkered Cloths-10X3', id:'cleaning10'}, {header:'Hair Nets-1X100', id:'cleaning11',num:11}, {header:'Gloves late-1X100', id:'cleaning12',num:12},{header:'Mop Bucket-1X1', id:'cleaning13',num:13}, {header:'Mop Head-10X1', id:'cleaning14',num:14},{header:'Jumbo Broom -24(1X1)', id:'cleaning15',num:15},{header:'Floor Scrubber -1X1', id:'cleaning16',num:16},{header:'Floor Squeezer -1X1', id:'cleaning17',num:17}, {num:18,header:'Dustbins with Lid SS foot operated(80L)-1X1', id:'cleaning18'},{num:19,header:'Small 40L plastic bins-1X1', id:'cleaning19'},{num:20,header:'Broom Stick With Dust pan-1X1', id:'cleaning20'}, {header:'Glass Cleaner -8X500Ml', id:'cleaning21',num:21}, {header:'Toilet Cleaner-1X1', id:'cleaning22',num:22},{header:'Homecare Caustic Soda -2 KG-6X2.55kg', id:'cleaning23',num:23}, {header:'Hard Broom With Stick -1X1s', id:'cleaning24',num:24},{header:'Elbow Grease Glass Cleaner -8X500ml', id:'cleaning15',num:25},{header:'Preema HD Green Scouring Pads -1X10s', id:'cleaning16',num:26}]

const InitialHeaders = [{num:'*', header:'Location', id:'store'},{num:'*', header:'Date', id:'dateFormat'},]
const CutVegetableHeaders =[{header:'Onion Round Slices (For Salad) -2Kg', id:'cut1',num:1},{header:'Onion Julienne -2Kg', id:'cut2',num:2},{header:'Onion Small Dice-2Kg', id:'cut3',num:3},{header:'Carrot Slices -2Kg', id:'cut4',num:4},{header:'Carrot Julienne-2Kg', id:'cut5',num:5},{header:'Carrot Brunoise-2Kg', id:'cut6',num:6},{header:'Cabbage Shredded-2Kg', id:'cut7',num:7,},{num:8,header:'Cabbage Small Dic-2Kg', id:'cut8'},{num:9,header:'Bell peppers Julienne-2Kg', id:'cut9'},{num:10,header:'Bell pepper Dice-2Kg', id:'cut10'},]
const DairyHeaders =[{header:'Natural Yoghurt -10KG', id:'dairy1',num:1},{header:'Greek Yoghurt -10KG', id:'dairy2',num:2},{header:'Single Cream -12 Lts', id:'dairy3',num:3},{header:'Paneer Cubes-5KG', id:'dairy4',num:4},{header:'Paneer Tikka -5KG', id:'dairy5',num:5}]
const DesertsHeaders =[{header:'Gulab jamun -200Pcs', id:'desert1',num:1},{header:'Qubani ka Meetha -5Kg(50+Servings)', id:'desert2',num:2},{header:'Rice Kheer -5Kg(50+Servings)', id:'desert3',num:3},{header:'kaddu ka Kheer -5Kg(50+Servings)', id:'desert4',num:4},              ]
const DrinksHeaders =[{header:'7UP Cans UK-24X330ml', id:'drink1',num:1},{header:'Coke Cans IMP-24X330ml', id:'drink2',num:2},{header:'Fanta Orange Cans-24X330ml', id:'drink3',num:3},{header:'Highland Spring Water Sparkling-24X500ml', id:'drink4',num:4},{header:'Sprite Cans-24X330ml', id:'drink5',num:5},{header:'Diet Coke Cans GB-24X330ml', id:'drink6',num:6},{header:'Avant Still Water 500ml -24X500ml', id:'drink7',num:7,},{num:8,header:'Thumbs up - 1x24', id:'drink8'},      ]
const EssentialsHeaders =[{header:'Frozen Mixed Vegetables -600gms', id:'essential1',num:1},{header:'Frozen Sweet Corn -500gms', id:'essential2',num:2},{header:'Frozen Spinach -600gms', id:'essential3',num:3},{header:'Thin and crispy French Fries -1.25Kg', id:'essential4',num:4},{header:'Frozen okra -300gms', id:'essential5',num:5},{header:'Pav Bread -6Pcs', id:'essential6',num:6},{header:'Whole Milk -2lts', id:'essential7',num:7,},{num:8,header:'Vanilla Ice cream -1.8L', id:'essential8'},{num:9,header:'Chocolate Ice cream -900ml', id:'essential9'},{num:10,header:'Kulfi Ice cream -1L', id:'essential10'}, {header:'Pure Ice Cubes -2Kg', id:'essential11',num:11}, {header:'Green Peas -500gms', id:'essential12',num:12},  ]
const GraviesHeaders = [{header:'Red Gravy -10lts', id:'gravy1',num:1},{header:'Onion Gravy-10lts', id:'gravy2',num:2},{header:'White Gravy -10lts', id:'gravy3',num:3},{header:'Biryani Gravy -10lts', id:'gravy4',num:4},{header:'Schezwan Sauce -5lts', id:'gravy5',num:5},{header:'Haleem -10lts', id:'gravy6',num:6},{header:'Veg Manchuria Balls- 5kg( approx 350-400balls)', id:'gravy7',num:7,},]
const MeatHeaders =[{header:'Chicken 65 -5Kg', id:'meat1',num:1},{header:'Chicken Curry cut(OFF Bone)-5Kg', id:'meat2',num:2},{header:'Chicken Doner-5Kg', id:'meat3',num:3},{header:'Chicken Drumsticks-5Kg', id:'meat4',num:4},{header:'Chicken Fillets-5Kg', id:'meat5',num:5},{header:'Chicken Keema-5Kg', id:'meat6',num:6},{header:'Chicken Legs-5Kg', id:'meat7',num:7,},{num:8,header:'Chicken Liver-5Kg', id:'meat8'},{num:9,header:'Chicken Lollipos-5Kg', id:'meat9'},{num:10,header:'Chicken Tandoori -5Kg', id:'meat10'}, {header:'Chicken Tikka-5Kg', id:'meat11',num:11}, {header:'Chicken Wings-5Kg', id:'meat12',num:12},{header:'Lamb Chops-5Kg', id:'meat13',num:13}, {header:'Lamb Curry Cut (OffBone)-5Kg', id:'meat14',num:14},{header:'Lamb Keema-5Kg', id:'meat15',num:15},{header:'Lamb Paya-5Pcs', id:'meat16',num:16},{header:'Lamb Shank-5KG', id:'meat17',num:17},{id:'meat18',num:18, header:'Biryani lamb-5Kg'} ]
const PackagingHeaders=[{header:'Probe Wipes-1X200', id:'packaging1',num:1},{header:'Probes-1X1', id:'packaging2',num:2},{header:'Foil Lined Bags 7x9x12 White-1X500s', id:'packaging3',num:3},{header:'No 9 Lids-1X200s', id:'packaging4',num:4},{header:'Mono Check Colour Tea Towels 3s -1X10s', id:'packaging5',num:5},{header:'Softesse White Toilet Rolls 18pk -3X18s', id:'packaging6',num:6},{header:'LLL 2inch No.9 Container -1X200s', id:'packaging7',num:7,},{num:8,header:'Paper Serviettes BOX 1ply  White 30x30 -1X5000s', id:'packaging8'},{num:9,header:'Eco Coffee Ripple Paper Cups & Lids 8oz -1X8s', id:'packaging9'},{num:10,header:'Majestic Jumbo Poly Wrap Cling Film  450mmx300m -6X300m', id:'packaging10'},{num:11,header:'BH Clear Plastic Forks -1X50s', id:'packaging11'},{num:12, id:'packaging12', header:'Jumbo Roll Catering Foil 300mmx25m -1X12s', },{num:13, id:'packaging13', header:'THL White Plastic Spoon -1X100s', },{num:14, id:'packaging14', header:'Round Stickers pack of 500', },{num:15, id:'packaging15', header:'Menu paper -500Nos', },{num:16, id:'packaging16', header:'Large Recyclable Kraft Brown Carrier Bag With Twisted Handles- 1x200', },{num:17, id:'packaging17', header:'Medium Recyclable Kraft Brown Paper Carrier Bag -1x250', },{num:18, id:'packaging18', header:'Wide Recyclable Kraft Brown Paper Carrier Bag - 1x250', },{num:19, id:'packaging19', header:'Thermal till roll (Epos) - 1x20', },{num:20, id:'packaging20', header:'Thermal roll (CC/Deliveroo) - 1x20', },{num:21, id:'packaging21', header:'Paper straws - 1x250', },{num:22, id:'packaging22', header:'Smoothie Cups and lids - 1x1000', },{num:23, id:'packaging23', header:'Disposable tea cups -1x500', },
]
const UniformsHeaders =[{header:'Chef Coat-1 Nos', id:'uniform1',num:1},{header:'Chef T shirt-1 Nos', id:'uniform2',num:2},{header:'Chef Apron-1 Nos', id:'uniform3',num:3},{header:'Chef cap-1 Nos', id:'uniform4',num:4},{header:'Front staff Shirt-1 Nos', id:'uniform5',num:5},{header:'Front staff apron-1 Nos', id:'uniform6',num:6},{header:'Porter T shirt-1 Nos', id:'uniform7',num:7},{header:'Porter Apron-1 Nos', id:'uniform8',num:8},{header:'Kitchen Helper T shirt-1 Nos', id:'uniform9',num:9},              ]
const SeaFoodHeaders = [{header:'Frozen Tilapia Fillets -800gms', id:'seafood1',num:1},{header:'Frozen King Prawns -700gms', id:'seafood2',num:2},]
const VegetablesHeaders = [{header:'Onion -24KG', id:'vegetable1',num:1},{header:'Bird Eye Chilli-4KG', id:'vegetable2',num:2},{header:'Button Mushroom-2.5KG', id:'vegetable3',num:3},{header:'Carrots -10KG', id:'vegetable4',num:4},{header:'Cauliflower-8Nos', id:'vegetable5',num:5},{header:'Coriander-20Nos', id:'vegetable6',num:6},{header:'Ginger-10KG', id:'vegetable7',num:7,},{num:8,header:'Cucumber-16KG', id:'vegetable8'},{num:9,header:'Limes-42Nos', id:'vegetable9'},{num:10,header:'Mint -20KG', id:'vegetable10'}, {header:'Mixed capsicum -12x400gms', id:'vegetable11',num:11}, {header:'Beetroot -10KG', id:'vegetable12',num:12},{header:'Peeled garlic -1KG', id:'vegetable13',num:13}, {header:'Pineapple -1Box', id:'vegetable14',num:14},{header:'Plum Vine Tomato -5KG', id:'vegetable15',num:15},{header:'Red Apple-12KG', id:'vegetable16',num:16},{header:'Spring onion-14KG', id:'vegetable17',num:17}, {num:18,header:'Vine tomato Royal-5KG', id:'vegetable18'},{num:19,header:'White Potato -20KG', id:'vegetable19'},{num:20,header:'White Large Cabbage -25KG', id:'vegetable20'}, {header:'Curry Leaves -20Nos', id:'vegetable21',num:21},]
const GroceryHeaders = [
  {num:1, id:'grocery1', header:'Heera Tamarind Sauce-1X1ltrs', },
  {num:2, id:'grocery2', header:'Amoy Soya Sauce  Dark -12X500ml',},
  {num:3, id:'grocery3', header:'De Rica Tomato Puree-12X850g'},
  {num:4, id:'grocery4', header:'Elephant Atta Self Raising-1X25kg'},
  {num:5, id:'grocery5', header:'Green Dragon Corn Flour -2 KG', },
  {num:6, id:'grocery6', header:'Lotus MSG-6X2kg'},
  {num:7, id:'grocery7', header:'Nawab Chunky Chat Masala-2 KG', },
  {num:8, id:'grocery8', header:'Nawab Deggi Mirch -2 KG', },
  {num:9, id:'grocery9', header:'Nawab Peacock Methi Leaves-2 KG', },
  {num:10, id:'grocery10', header:'T&L Granulated Sugar-2 KG', }, 
  {num:11, id:'grocery11', header:'Nawab Black Pepper Whole -2 KG', },
  {num:12, id:'grocery12', header:'Nawab Cardamoms Black -2 KG',},
  {num:13, id:'grocery13', header:'Nawab Chilli Powder -2 KG'},
  {num:14, id:'grocery14', header:'Nawab Cloves Whole -2 KG'},
  {num:15, id:'grocery15', header:'Nawab Coconut Powder -2 KG', },
  {num:16, id:'grocery16', header:'Nawab Dhania Powder (Indori) -2 KG'},
  {num:17, id:'grocery17', header:'Nawab Haldi Powder (Turmeric) -2 KG', },
  {num:18, id:'grocery18', header:'Nawab Javentry Mace -2 KG', },
  {num:19, id:'grocery19', header:'Nawab Jeera Powder -2 KG', },
  {num:20, id:'grocery20', header:'Nawab Jeera Whole -2 KG', },
  {num:21, id:'grocery21', header:'Nawab Lentils Red (Masoor Dall) -2 KG', },
  {num:22, id:'grocery22', header:'Nawab Madras Papadoms -2 KG',},
  {num:23, id:'grocery23', header:'Nawab Methi Seeds -2 KG'},
  {num:24, id:'grocery24', header:'Nawab Moong Dall -2 KG'},
  {num:25, id:'grocery25', header:'Nawab Mustard Seeds (Brown) -2 KG', },
  {num:26, id:'grocery26', header:'Nawab Peanuts Red -2 KG'},
  {num:27, id:'grocery27', header:'Nawab Sesame Seeds Hulled -2 KG', },
  {num:28, id:'grocery28', header:'Nawab Star Aniseed (Badian) -2 KG', },
  {num:29, id:'grocery29', header:'Nawab Tamarind (Imli) Indian -2 KG', },
  {num:30, id:'grocery30', header:'Nawab Tamarind Concentrate -2 KG', },
  {num:31, id:'grocery31', header:'Ajino motoNawab Toor Dall Plain -2 KG', },
  {num:32, id:'grocery32', header:'Nawab Gram Flour -2 KG',},
  {num:33, id:'grocery33', header:'Nawab Cardamoms Green -2 KG'},
  {num:34, id:'grocery34', header:'Nawab Chana Masala -2 KG'},
  {num:35, id:'grocery35', header:'Nawab Chick Peas-2 KG', },
  {num:36, id:'grocery36', header:'Nawab Black Pepper Powder -2 KG'},
  {num:37, id:'grocery37', header:'Nawab Black Pepper Coarse -2 KG', },
  {num:38, id:'grocery38', header:'Nawab White Pepper Powder -2 KG', },
  {num:39, id:'grocery39', header:'Nawab Cashew Kernels -2 KG', },
  {num:40, id:'grocery40', header:'Top Op Mukhwas Gujarati -2 KG', },
  {num:41, id:'grocery41', header:'Nawab Kala Jeera -2 KG', },
  {num:42, id:'grocery42', header:'Nawab Almond Powder -2 KG',},
  {num:43, id:'grocery43', header:'Nawab Garam Masala -2 KG'},
  {num:44, id:'grocery44', header:'Top Op Dry Apricots -2 KG'},
  {num:45, id:'grocery45', header:'Rajah Whole Methi Seeds -2 KG', },
  {num:46, id:'grocery46', header:'Nawab Dalchini Chinese Whole -2 KG'},
  {num:47, id:'grocery47', header:'Nawab Cashew Kernels - 2KG', },
  {num:48, id:'grocery48', header:'Top Op Mukhwas Gujarati', },
  {num:49, id:'grocery49', header:'Nawab Lemon Juice -6X946ml', },
  {num:50, id:'grocery50', header:'ENW Cooking Salt -1X12.5kg', },
  {num:51, id:'grocery51', header:'Nawab Kesar Mango Pulp -6X850g', },
  {num:52, id:'grocery52', header:'Top Op Dry Apricots - 2',},
  {num:53, id:'grocery53', header:'Rajah Whole Methi Seeds - 2'},
  {num:54, id:'grocery54', header:'Shankar Whole Dry Chilli Stemless PM1.29-1X100g'},
  {num:55, id:'grocery55', header:'Long Life Egg Noodles -50X250g', },
  {num:56, id:'grocery56', header:'Heera Bay Leaves -1X1kg'},
  {num:57, id:'grocery57', header:'Nawab Chilli Powder Kashmiri -10X400g', },
  {num:58, id:'grocery58', header:'Elephant Atta Medium PM17.29 -1X25kg', },
  {num:59, id:'grocery59', header:'Preema Deep Orange Food Colour 1X500g', },
  {num:60, id:'grocery60', header:'Preema Bright Red Food Colour -1X500g', },
  {num:61, id:'grocery61', header:'Preema Egg Yellow Food Colour -1X500g', },
  {num:62, id:'grocery62', header:'Nawab Chicken Curry Masala -4X500g',},
  {num:63, id:'grocery63', header:'Heera Cardamom Powder -20X50g'},
  {num:64, id:'grocery64', header:'Premium Loose Tea PM7.99 -1X1kg'},
  {num:65, id:'grocery65', header:'Dawat Basmati Rice -1X20Kg', },
  {num:66, id:'grocery66', header:'Condensed Milk- 6X397g'},
  {num:67, id:'grocery67', header:'Raisins -20X100', },
  ]


const SingleWalaInventorySheet = ({data}) =>{
    const biryaniRef = useRef(null);
    
    const [stock, setStock] = useState(data.length ===0 ?[]:data)
    useEffect(()=>{
        setStock(data.length ===0 ?[]:data)
      },[data])
    return(
        <div>
             <div style={{ border: '1px solid #DCDCDC', padding: 10, }}>
                    <div style={{ padding: 5, paddingBottom: 0, paddingTop: 0 }}>
                        <div className='row' style={{alignItems:'center',marginBottom:10}}>
                            <div className='col-md-9'>
                        <p style={{ textAlign: 'left', marginTop: 10, color: '#048E5B', fontSize: 25 }}>Inventory Sheet</p>
                        </div>
                      <div div className='col-md-3' style={{display:'flex'}}>
                <DownloadTableExcel
                  filename={'Inventory Sheet'}
                  sheet="Inventory"
                  currentTableRef={biryaniRef.current}
                >

                  <button style={{fontSize:20,height:40, color:'#FFFFFF',backgroundColor:'#048E5B',borderWidth:0,borderRadius:5}} ><img src={excel} alt="Logo" style={{width:18, height:20}}/> Export excel </button>

                </DownloadTableExcel>
              </div>
                        </div>
                        <table class="table-bordered " ref={biryaniRef}>
                        {InitialHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,backgroundColor:'#75757529'}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'left', backgroundColor:'#75757529'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,backgroundColor:'#75757529'}}>{content[header['id']]}</td>
                  ))}
              </tr>
                ))}
                       
                       
                        <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:250, height:20, textAlign:'left'}}>Cleaning Supplies</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {CleaningHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>GRAVIES</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {GraviesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Soft Drinks</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {DrinksHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Vegetables</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {VegetablesHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                           <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Essentials</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {EssentialsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Groceries</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {GroceryHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Packaging</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {PackagingHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                             <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>UNIFORMS</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {UniformsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                        <tr>

                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Desserts</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {DesertsHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Dairy</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {DairyHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Sea Food</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {SeaFoodHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                           <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Raw Meat</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {MeatHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left' }}>Cut Vegetables</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                        {CutVegetableHeaders.map((header)=>(
                  <tr key={header.id} >
                     <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20,}}>{header.num}</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20, textAlign:'right'}}>{header.header}</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content[header['id']]}</td>
                  ))}
              </tr>
                        ))}
                         <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'left'}}>Others</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}></td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>1</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product1</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title1}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity1</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other1}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>2</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product2</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title2}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity2</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other2}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>3</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product3</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title3}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity3</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other3}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>4</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product4</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title4}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity4</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other4}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>5</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product5</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title5}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity5</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other5}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>6</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product6</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title6}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity6</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other6}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>7</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product7</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title7}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity7</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other7}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>8</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product8</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title8}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity8</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other8}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>9</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product9</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title9}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity9</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other9}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>10</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Product10</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.title10}</td>
                  ))}
                          </tr>
                          <tr>
                          <th style={{color:'#1E4388', fontSize:12, borderColor:'#9FC2F1', width:50, height:20}}>*</th>
                  <th style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1', width:200, height:20,textAlign:'right' }}>Quantity10</th>
                  {data.map((content) => (
                    
                    <td  style={{color:'#1E4388', fontSize:14, borderColor:'#9FC2F1',width:150,}}>{content.other10}</td>
                  ))}
                          </tr>
                       
                        </table>
                    </div>
                </div>
        </div>
    )
}
export default SingleWalaInventorySheet;